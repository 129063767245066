import * as Yup from 'yup';
import dayjs from 'dayjs';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const missingField = '此填必須輸入'
const overMax = '字數超出限制'
const numberField = '此填必須為數字'
const notPositiveNumber = '此填必須大於零'
const notNonNegativeNumber = '此填不能是負數'
const notEmail = '電郵格式錯誤'
const notPhone = '號碼格式錯誤'
const mustSelect = '此填必須選擇'
const endTimeShouldBigger = '結束時間必須比開始時間晚'
const conflictPriority = '同一日子不能有相同的志願'
const fixedLengthError = '字數必須為'
const checkboxError = '必須選擇'
const enterDate = "必須輸入日期"


const date = () => {
    return Yup.date().nullable().required(enterDate);
}
const textField = (max) => {
    if (!max) {
        return Yup.string().required(missingField);
    }
    return Yup.string().max(max, overMax).required(missingField);
}

const notRequired = (max) => {
    return Yup.string().max(max, overMax);
}

const positiveNumber = (max) => {
    return Yup.number().positive(notPositiveNumber).max(max, overMax).required(missingField).typeError(numberField);
}

const nonNegativeNumber = (max) => {
    return Yup.number().min(0, notNonNegativeNumber).max(max, overMax).required(missingField).typeError(numberField);
}

const email = (max, isManda = true) => {
    if (isManda) {
        return Yup.string().email(notEmail).max(max, overMax).required(missingField);
    }
    return Yup.string().email(notEmail).max(max, overMax);
}

const phoneNumber = (max) => {
    return Yup.string().matches(phoneRegExp, notPhone).max(max, overMax).required(missingField);
}

const multipleNumber = (arr) => {
    return Yup.number().oneOf(arr).required(missingField);
}

const boolean = () => {
    return Yup.bool().required(missingField);
}

const listView = (listValidate) => {
    return Yup.array().of(
        Yup.object().shape(listValidate)
    );
}

const isObject = () => {
    return Yup.object().required(missingField);
}

const isOptionSelected = () => {
    // since we are using 'react-select', all options should be object
    return Yup.object().test('isSelected', mustSelect,
        function (selected) {
            if (!selected || !selected.value) {
                return false;
            }
            return true;
        });
}

const fromToTime = () => {
    return Yup.mixed().test('isLarger', endTimeShouldBigger, (value, testContext) => {
        if (!value || !value.value) {
            return false;
        }
        const current = value.value;
        const parent = testContext?.parent?.start?.value;
        if (!parent) {
            return false;
        }

        if (current < parent) {
            return false
        }
        return true;
    })
}

const fromToDate = () => {
    return Yup.mixed().test('isLarger', endTimeShouldBigger, (value, testContext) => {
        if (!value) {
            return false;
        }

        const parent = testContext?.parent?.startDate;

        const startDate = dayjs(parent);
        const endDate = value;

        if (!parent) {
            return false;
        }

        if (startDate.isAfter(endDate)) {
            return false
        }
        return true;
    })
}

const bookingTimeSlotField = 'bookingTimeslots';
const bookingPriority = () => {
    return Yup.mixed().required(missingField).test('priority', conflictPriority, (value, testContext) => {
        if (!value) {
            return false;
        }

        const parent = testContext.parent;
        const targetDate = dayjs(parent.date).format('YYYY-MM-DD');

        const from = testContext.from;
        let bookingTimeslots = [];
        for (let i = 0; i < from.length; i++) {
            const current = from[i];
            const currentValue = current.value;
            for (const key in currentValue) {
                if (key === bookingTimeSlotField) {
                    bookingTimeslots = currentValue[key];
                    break;
                }
            }
        }

        let sameField = 0;
        for (let i = 0; i < bookingTimeslots.length; i++) {
            const current = bookingTimeslots[i];
            const currentDate = dayjs(current.date).format('YYYY-MM-DD');

            const currentValue = value ? value.toString().trim() : '';
            const currentPreferPriority = current.preferPriority ? current.preferPriority.toString().trim() : '';
            if (currentValue === currentPreferPriority && currentDate === targetDate) {
                sameField++;
            }
        }

        return sameField === 1;
    })
}

const eitherOr = (otherField, errorMessage) => {
    return Yup.mixed().test('eitherOr', errorMessage, (value, testContext) => {
        if (value) {
            return true;
        }

        const parent = testContext.parent;
        if (parent[otherField]) {
            return true;
        }

        return false;
    })
}

const checkbox = () => {
    return Yup.mixed().test('checkbox', checkboxError, (value) => {
        if (value) {
            return true;
        }
        return false;
    })
}

const emailRegez = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
const validateSchemaValue = (value, schema) => {
    if (!value) {
        return false
    }
    switch (schema.type) {
        case schemaType.Select: {
            if (!value) {
                return false
            }
            return true
        }
        case schemaType.FixedLength: {
            if (!value) {
                return false
            }
            return value.toString().length === schema.typeValue
        }

        case schemaType.Email:
            return value.match(emailRegez)
        case schemaType.TextInput:
            return !!value
    }
    return false
}

const eitherOrList = (validationSchema, baseValidation, errorMessage) => {
    return baseValidation.test('eitherOrList', errorMessage, (value, testContext) => {
        const parent = testContext.parent;
        let firstListValid = true
        let secondListValid = true
        const schemaFirst = validationSchema[0]
        const schemaSecond = validationSchema[1]

        for (let i = 0; i < schemaFirst.list.length; i++) {
            const current = schemaFirst.list[i];
            const currentValue = parent[current.id]
            if (!validateSchemaValue(currentValue, current)) {
                firstListValid = false
                break
            }
        }

        for (let j = 0; j < schemaSecond.list.length; j++) {
            const current = schemaSecond.list[j];
            const currentValue = parent[current.id]
            if (!validateSchemaValue(currentValue, current)) {
                secondListValid = false
                break
            }
        }

        if (firstListValid || secondListValid) {
            return true
        }

        return false
    });
}

const fixedLength = (len, isManda = true) => {
    if (isManda) {
        return Yup.string().min(len, fixedLengthError + len).max(len, fixedLengthError + len).required(missingField)
    }
    return Yup.string().nullable().min(len, fixedLengthError + len).max(len, fixedLengthError + len);
}

const emailIfRequired = (max, requiredField) => {
    return Yup.mixed().test('emailIfRequired', missingField, (value, testContext) => {
        const parent = testContext.parent;
        const required = parent[requiredField];
        if (required && required.value) {
            if (!value) {
                return false;
            }
            return true;
        }
        return true;
    }).test('emailformat', notEmail, (value, testContext) => {
        const parent = testContext.parent;
        const required = parent[requiredField];
        if (required && required.value) {
            if (!value) {
                return false;
            }
            return value.match(emailRegez);
        }
        return true;
    }).test('emailMax', overMax, (value, testContext) => {
        if (!value) {
            return true;
        }
        return value.length <= max;
    });
}

const adminIgnoredField = (roleField, roleList, isPhone = false, isEmail = false) => {
    // if it is admin, ignore the field
    return Yup.mixed().test('adminIgnoredField', missingField, (value, testContext) => {
        const parent = testContext.parent;
        const role = parent[roleField];
        if (roleList.includes(role?.label)) {
            return true;
        }
        return !!value;
    }).test('phoneNumber', notPhone, (value, testContext) => {
        if (isPhone) {
            if (!value) {
                return true;
            }
            return value.match(phoneRegExp);
        }
        return true;
    }).test('emailFormat', notEmail, (value, testContext) => {
        if (isEmail) {
            if (!value) {
                return true;
            }
            return value.match(emailRegez);
        }
        return true;
    })
}

export const specializeErrorMessage = {
    eitherOrIdentification: '必須填寫任一個身份證明',
    eitherOrAssociation: '必須填寫註冊編號或商業登記證號碼',
    mustFillEitherOrCoach: '必須填寫任一個教練資料',
}

export const schemaType = {
    Select: 'Select',
    FixedLength: 'FixedLength',
    Email: 'Email',
    TextInput: 'TextInput',
}

const YupValidate = {
    baseYup: Yup,
    textField,
    notRequired,
    positiveNumber,
    nonNegativeNumber,
    email,
    phoneNumber,
    multipleNumber,
    boolean,
    listView,
    isObject,
    isOptionSelected,
    fromToTime,
    fromToDate,
    bookingPriority,
    fixedLength,
    eitherOr,
    checkbox,
    date,
    schemaType,
    eitherOrList,
    emailIfRequired,
    adminIgnoredField,
}

export default YupValidate