export const stringToColor = (str) => {
    let hash = 0;
    let i;
  
    for (i = 0; i < str.length; i += 1) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
  
    return color;
  }

export const parseAssociationName = (association) => {
    if (association) {
        return association.chineseName;
    }

    return association.englishName;
}

export const parseUserName = (user) => {
    let name = '';
    if (user.chineseFirstName && user.chineseLastName) {
        name = `${user.chineseLastName} ${user.chineseFirstName}`;
    } else if (user.chineseFirstName) {
        name = user.chineseFirstName;
    } else if (user.chineseLastName) {
        name = user.chineseLastName;
    }

    if (!name) {
        if (user.englishFirstName && user.englishLastName) {
            name = `${user.englishFirstName} ${user.englishLastName}`;
        } else if (user.englishFirstName) {
            name = user.englishFirstName;
        } else if (user.englishLastName) {
            name = user.englishLastName;
        }
    }

    if (!name) {
        name = user.username;
    }

    return name;
}

export const parseEnglishName = (user) => {
    if (user.englishFirstName && user.englishLastName) {
        return `${user.englishFirstName} ${user.englishLastName}`;
    } else if (user.englishFirstName) {
        return user.englishFirstName;
    } else if (user.englishLastName) {
        return user.englishLastName;
    }

    return '';
}

export const parseChineseName = (user) => {
    if (user.chineseFirstName && user.chineseLastName) {
        return `${user.chineseLastName} ${user.chineseFirstName}`;
    } else if (user.chineseFirstName) {
        return user.chineseFirstName;
    } else if (user.chineseLastName) {
        return user.chineseLastName;
    }

    return '';
}