// project import
import dashboard from './dashboard';
import association from './association';
import manage from './manage';
import course from './course';
import changeRequest from './changerequest'
import booking from './booking';
import setting from './setting';
import report from './report';

const list = [
    dashboard,
    association,
    manage,
    course,
    changeRequest,
    //booking,
    setting,
    report
];

export const getDrawItems = (userRole) => {
    const items = [];
    for (let i = 0; i < list.length; i++) {
        const group = list[i];
        const innerChildren = [];
        for (let j = 0; j < group.children.length; j++) {
            const current = group.children[j];
            if (current.drawer) {
                let authorized = false;
                if (current.roles === '*') {
                    authorized = true;
                } else if (current.roles.includes(userRole)) {
                    authorized = true;
                }

                if (authorized) {
                    const url = `/${group.path}/${current.path}`;
                    innerChildren.push({
                        id: current.id,
                        title: current.title,
                        type: 'item',
                        url,
                        icon: current.icon,
                        breadcrumbs: false,
                    })
                }
            }
        }
        if (innerChildren.length !== 0) {
            items.push({
                id: `group-${group.path}`,
                title: group.title,
                type: 'group',
                children: innerChildren,
            })
        }
    }

    return {
        items,
    }
}

export const isAuthorized = (path, userRole) => {
    const pathArr = path.substring(1).split('/');
    for (let i = 0; i < list.length; i++) {
        if (pathArr[0] === list[i].path) {
            const children = list[i].children;
            for (let j = 0; j < children.length; j++) {
                if (pathArr[1] === children[j].path) {
                    // check authorization
                    if (children[j].roles === '*') {
                        return true;
                    }
                    if (children[j].roles.includes(userRole)) {
                        return true;
                    }
                }
            }
        }
    }
    
    return true;
}

export const getRoutes = () => {
    const mainChildren = [];
    for (let i = 0; i < list.length; i++) {
        const group = list[i];
        const innerChildren = [];
        for (let j = 0; j < group.children.length; j++) {
            const current = group.children[j];
            innerChildren.push({
                path: current.path,
                element: current.page,
            });
        }
        mainChildren.push({
            path: group.path,
            children: innerChildren,
        })
    }
    return mainChildren;
}
