import { useCallback } from 'react';
import {
    Grid,
    Button,
    Stack,
    InputLabel,
} from '@mui/material';

export default function ButtonField({
    label,
    subtitle,
    functionIdentifier,
    identifier,
    icon,
    xs,
    handlerLists,
}) {
    const onClickHandler = useCallback(() => {
        if (functionIdentifier && handlerLists) {
            if (handlerLists[functionIdentifier]) {
                handlerLists[functionIdentifier]();
            }
        }
    }, [functionIdentifier, handlerLists]); 
    return (
        <Grid item xs={xs}>
            <Stack spacing={1}>
                <InputLabel html={identifier}>{label}</InputLabel>
                {subtitle && (<InputLabel>{subtitle}</InputLabel>)}
                <Button
                    onClick={onClickHandler}
                    variant="outlined"
                >{icon}</Button>
            </Stack>
        </Grid>
    )
}