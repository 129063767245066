import { constant } from 'lodash';
import { parseUserName, parseAssociationName } from 'utils/helper/display';
import siteconstant from 'utils/constant';
import dayjs from 'dayjs';
export const defaultOption = { label: '請選擇或搜尋', value: null };

// if there is no specific filtering method, it will use label to filter to select option
export const genericFiltering = (list, inputValue) => list.filter(s => inputValue ? s.label.toLowerCase().includes(inputValue.toLowerCase()) : true);

export const parseArrayToSelect = (arr) => {
    return arr.map((s) => {
        return {
            label: s,
            value: s,
        }
    })
}

export const parseUserToSelect = (user) => {
    const name = parseUserName(user);

    let label = name
    if (user.membershipNo) {
        label = `${name} | ${user.membershipNo}`;
    }

    return {
        label: label,
        value: user.id,
    }
}

export const mapUserListToSelect = (users) => {
    return users.map((s) => {
        return parseUserToSelect(s);
    })
}
export const praseUserSelectList = (data, inputValue, isMulti = false) => {
    let base = [];
    if (!isMulti) {
        base = [defaultOption];
    }
    try {
        const content = data?.data?.content;

        const list = content.map((s) => {
            return {
                label: s.chineseLastName + s.chineseFirstName + "(" + s.membershipNo + ")",
                value: s.id,
            }
        });

        const filtered = genericFiltering(list, inputValue);

        return [...base, ...filtered];
    } catch (e) {
        console.log(e);
    }

    return base;
}
export const parseCertificateSelectList = (data, inputValue, isMulti = false) => {
    let base = [];
    if (!isMulti) {
        base = [defaultOption];
    }
    try {
        const content = data?.data?.content;

        const list = content.map((s) => {
            return {
                label: getCourseLevel(s.courseLevel) + parseSportTypeName(s.sportType) + " (到期日 : " + (s.expiryDate !== null ? dayjs(s.expiryDate).format('YYYY-MM-DD') : null) + ")",
                value: s.id,
            }
        });

        const filtered = genericFiltering(list, inputValue);

        return [...base, ...filtered];
    } catch (e) {
        console.log(e);
    }

    return base;
}
const getCourseLevel = (courseLevel) => {
    if (courseLevel !== undefined) {
        const data = siteconstant.CourseLevel.find(a => a.value === courseLevel);
        if (data !== null && data.value !== null) {
            return data.label;
        }
    }
    return "";
}
// parsing function for association list
// AsociationService.list()
export const parseAssociationList = (data, inputValue, isMulti = false) => {
    let base = [];
    if (!isMulti) {
        base = [defaultOption];
    }
    try {
        const content = data?.data?.content;

        const list = content.map((s) => {
            return {
                label: parseAssociationName(s),
                value: s.id,
            }
        });

        const filtered = genericFiltering(list, inputValue);

        return [...base, ...filtered];
    } catch (e) {
        console.log(e);
    }

    return base;
}

// parsing function for userroles
// UserService.metadata()
export const parseUserRolesList = (data, inputValue, isMulti = false) => {
    let base = [];
    if (!isMulti) {
        base = [defaultOption];
    }
    try {
        const content = data?.data?.userroles;

        const list = content.map((s) => {
            return {
                label: s.name,
                value: s.id,
            }
        });

        const filtered = genericFiltering(list, inputValue);

        return [...base, ...filtered];
    } catch (e) {
        console.log(e);
    }

    return base;
}

export const parseSportTypeName = (sportType) => {
    if (!sportType) return '';
    if (sportType.chineseName && sportType.englishName) {
        return `${sportType.chineseName} | ${sportType.englishName}`;
    } else if (sportType.chineseName) {
        return sportType.chineseName;
    } else {
        return sportType.englishName;
    }
}

// parsing function for sport type
// CourseService.sportTypes()
export const parseSportTypeList = (data, inputValue, isMulti = false) => {
    let base = [];
    if (!isMulti) {
        base = [defaultOption];
    }
    try {
        const content = data?.data?.content;

        const list = content.map((s) => {
            return {
                label: parseSportTypeName(s),
                noLevels: s.noLevels,
                value: s.id,
            }
        });

        const filtered = genericFiltering(list, inputValue);

        return [...base, ...filtered];
    } catch (e) {
        console.log(e);
    }

    return base;
}
export const parseSportTypeTeachAbleList = (data, inputValue, isMulti = false) => {
    let base = [];
    if (!isMulti) {
        base = [defaultOption];
    }
    try {
        const content = data?.data?.content;

        const list = content.map((s) => {
            return {
                label: parseSportTypeName(s),
                noLevels: s.noLevels,
                value: s.id,
                teachableLevels: s.teachableLevels
            }
        });

        const filtered = genericFiltering(list, inputValue);

        return [...base, ...filtered];
    } catch (e) {
        console.log(e);
    }

    return base;
}

// parsing function user list
// UserService.list()
export const parseUserList = (data, inputValue, isMulti = false) => {
    let base = [];
    if (!isMulti) {
        base = [defaultOption];
    }
    try {
        const content = data?.data?.content;

        const list = mapUserListToSelect(content);

        const filtered = genericFiltering(list, inputValue);

        return [...base, ...filtered];
    } catch (e) {
        console.log(e);
    }

    return base;
}

// parsing function coach list
// UserService.list()
export const parseCoachList = (data, inputValue, isMulti = false) => {
    let base = [];
    if (!isMulti) {
        base = [defaultOption];
    }
    try {
        const content = data?.data?.content;

        const list = mapUserListToSelect(content);

        const filtered = genericFiltering(list, inputValue);

        return [...base, ...filtered];
    } catch (e) {
        console.log(e);
    }

    return base;
}

// parsing function coach assistant list
// UserService.list()
export const parseCoachWithAssistantList = (data, inputValue, isMulti = false) => {
    let base = [];
    if (!isMulti) {
        base = [defaultOption];
    }
    try {
        const content = data?.data?.content;

        const list = mapUserListToSelect(content);

        const filtered = genericFiltering(list, inputValue);

        return [...base, ...filtered];
    } catch (e) {
        console.log(e);
    }

    return base;
}

// parsing function firstAid List
export const parseFirstAidList = (data, inputValue, isMulti = false) => {
    let base = [];
    if (!isMulti) {
        base = [defaultOption];
    }
    try {
        const content = data?.data?.content;

        const list = mapUserListToSelect(content);

        const filtered = genericFiltering(list, inputValue);

        return [...base, ...filtered];
    } catch (e) {
        console.log(e);
    }

    return base;
}

// parsing function for user under association
// AssociationService.get()
export const parseAssociationUserList = (data, inputValue, isMulti = false) => {
    let base = [];
    if (!isMulti) {
        base = [defaultOption];
    }

    try {
        const content = data?.data?.userlist;

        const list = mapUserListToSelect(content);

        const filtered = genericFiltering(list, inputValue);

        return [...base, ...filtered];
    } catch (e) {
        console.log(e);
    }
    return base;
}

// parsing function for course
// CourseService.list()
export const parseCourseList = (data, inputValue, isMulti) => {
    let base = [];
    if (!isMulti) {
        base = [defaultOption];
    }

    try {
        const content = data?.data?.content;

        const list = content.map((s) => {
            return {
                label: s.name,
                value: s.id,
            }
        });

        const filtered = genericFiltering(list, inputValue);

        return [...base, ...filtered];
    } catch (e) {
        console.log(e);
    }

    return base;
}

// parsing function for booking venus
export const parseBookingVenusList = (data) => {
    let base = [];
    try {
        const content = data?.data?.content;
        
        const list = content.map((s) => {
            return {
                label: s.name,
                value: s.id,
            }
        })

        return [...base, ...list];
    } catch (e) {
        console.log(e);
    }

    return base;
}