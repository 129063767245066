import {
    Grid,
    OutlinedInput,
    Stack,
    InputLabel,
    FormHelperText,
    InputAdornment,
} from '@mui/material';

import DateField from './DateField';
import DateTimeField from './DateTimeField'
import SelectField from './SelectField';
import AsyncSelectField from './AsyncSelectField';
import ButtonField from './ButtonField';
import ListField from './ListField';
import ComponentField from './ComponentField';
import ColorField from 'components/field/ColorField';
import ImageField from 'components/field/ImageField';
import CheckboxField from 'components/field/CheckboxField';


import SearchAsyncSelectField from '../inputfield/AsyncSelectField';
import SearchSelectField from '../inputfield/SelectField';
import SearchDateField from '../inputfield/DateField';

export default function DynamicField(props) {
    const type = props.type;
    const isSearch = props.isSearch;

    if (isSearch) {
        if (type === 'asyncselect' || type === 'asyncselectmulti') {
            return (
                <SearchAsyncSelectField
                    isMulti={type === 'asyncselectmulti'}
                    {...props}
                />
            )
        }
        if (type === 'select' || type === 'selectmulti') {
            return (
                <SearchSelectField
                    isMulti={type === 'selectmulti'}
                    {...props}
                />
            )
        }
        if (type === 'date') {
            return (
                <SearchDateField
                    {...props}
                />
            )
        }
    }

    if (type === 'date') {
        return (
            <DateField {...props} />
        )
    }
    if (type === 'datetime') {
        return (
            <DateTimeField {...props} />
        )
    }
    if (type === 'asyncselect' || type === 'asyncselectmulti') {
        return (
            <AsyncSelectField
                isMulti={type === 'asyncselectmulti'}
                {...props}
            />
        )
    }
    if (type === 'select' || type === 'selectmulti') {
        return (
            <SelectField
                isMulti={type === 'selectmulti'}
                {...props}
            />
        )
    }
    if (type === 'button') {
        return (
            <ButtonField {...props} />
        )
    }
    if (type === 'list') {
        return (
            <ListField {...props} />
        )
    }
    if (type === 'component') {
        return (
            <ComponentField {...props} />
        )
    }
    if (type === 'color') {
        return (
            <ColorField {...props} />
        )
    }
    if (type === 'image') {
        return (
            <ImageField {...props} />
        )
    }

    if (type === 'checkbox') {
        return (
            <CheckboxField {...props} />
        )
    }

    const {
        id,
        identifier,
        xs,
        label,
        subtitle,
        suffix,
        value,
        limit,
        touched,
        errorMessage,
        handleBlur,
        handleChange,
        upperCase,
        index,
    } = props;

    if (type === 'label' || type === 'index') {
        const {
            fontWeight,
            alignItems,
            labelValue,
            labelType,
            paddingTop,
            list,
        } = props;

        let displayText = label ?? '';
        if (labelType === 'object') {
            if (value && value[labelValue]) {
                displayText += ` ${value[labelValue]}`;
            }
        } else if (labelType === 'select') {
            const obj = list.find(s => s.value === value);
            if (obj) {
                displayText += ` ${obj.label}`;
            }
        } else if (labelType === 'index') {
            displayText += `${index + 1}`;
        } else {
            displayText += ` ${value ?? ''}`;
        }

        return (<Grid item xs={xs} style={{
            fontWeight: fontWeight ?? 'normal',
            display: 'flex',
            alignItems: alignItems ?? 'center',
            paddingTop: paddingTop ?? null,
        }}>
            {displayText}
        </Grid>);
    }

    const onTextChange = (e) => {
        if (limit) {
            if (e.target.value.length > limit) {
                e.target.value = e.target.value.substring(0, limit);
            }
        }

        if (upperCase) {
            e.target.value = e.target.value.toUpperCase();
        }

        handleChange(e);
    }

    return (
        <Grid item xs={xs}>
            <Stack spacing={1}>
                <InputLabel html={identifier}>{label}</InputLabel>
                {subtitle && (<InputLabel>{subtitle}</InputLabel>)}
                <OutlinedInput
                    id={identifier}
                    value={value}
                    name={id}
                    onBlur={handleBlur}
                    onChange={onTextChange}
                    endAdornment={suffix ? <InputAdornment position="end">{suffix}</InputAdornment> : null}
                    type={type === 'password' ? 'password' : 'text'}
                    placeholder=""
                    fullWidth
                    error={Boolean(touched && errorMessage)}
                />
                {touched && errorMessage && (
                    <FormHelperText error id={`helper-text-${identifier}`}>
                        {errorMessage}
                    </FormHelperText>
                )}
            </Stack>
        </Grid>
    )
}