import axios from './interceptor';

const list = (data) => {
    return axios.get(`/system/membershipyears`, { params: data });
}
const get = (id) => {
    return axios.get(`/system/membershipyear`, { params: { id } });
}

const manage_year = (data) => {
    return axios.post(`/system/managemembershipyear`, data);
}

const report = {
    list,
    get,
    manage_year
}

export default report;