import { lazy } from 'react';
import Loadable from 'components/Loadable';

// assets
import { MdCalendarToday,MdPeople } from 'react-icons/md';

// icons
const icons = {
    MdCalendarToday,
    MdPeople
};

const CourseManageList = Loadable(lazy(() => import('pages/course/CourseManageList')));
const ParticipantList = Loadable(lazy(() => import('pages/course/ParticipantList')));
import constant from '../utils/constant';
const UserRoles = constant.UserRoles;

const courseManage = {
    id: 'coursemanage',
    title: '管理',
    path: 'coursemanagelist',
    page: <CourseManageList />,
    icon: icons.MdCalendarToday,
    drawer: true,
    roles: [ UserRoles.SystemAdmin, UserRoles.HeadAdmin, UserRoles.AssoAdmin, UserRoles.Coach ],
}

const participantList = {
    id: 'participantList',
    title: '搜尋參與者',
    path: 'participant',
    page: <ParticipantList />,
    icon: icons.MdPeople,
    drawer: true,
    roles: [ UserRoles.SystemAdmin, UserRoles.HeadAdmin ],
}
const course = {
    path: 'course',
    title: '課程',
    children: [ participantList ],
}

export default course;