import { lazy } from 'react';
import Loadable from 'components/Loadable';

import { FaList } from 'react-icons/fa';
import { GiMountainClimbing } from 'react-icons/gi';
import { GoChecklist } from 'react-icons/go';
import { TbListSearch } from 'react-icons/tb';

const icons = {
    GiMountainClimbing,
    FaList,
    GoChecklist,
    TbListSearch,
}

const BookingList = Loadable(lazy(() => import('pages/booking/BookingList')));
const BookingApprovePage = Loadable(lazy(() => import('pages/booking/BookingApprovePage')));
const BookingAssociationViewPage = Loadable(lazy(() => import('pages/booking/BookingAssociationViewPage')));

import constant from '../utils/constant';
const UserRoles = constant.UserRoles;

const bookingList = {
    id: 'booking-list',
    title: '租借列表',
    path: 'list',
    page: <BookingList />,
    icon: icons.GiMountainClimbing,
    drawer: true,
    roles: [UserRoles.SystemAdmin, UserRoles.Coach, UserRoles.AssoAdmin],
};

const bookingApprovePage = {
    id: 'booking-approve',
    title: '處理場地預約',
    path: 'approve',
    page: <BookingApprovePage />,
    icon: icons.GoChecklist,
    drawer: true,
    roles: [ UserRoles.SystemAdmin, UserRoles.HeadAdmin ],
}

const bookingAssociationViewPage = {
    id: 'booking-asso-view',
    title: '以屬會分類',
    path: 'associationview',
    page: <BookingAssociationViewPage />,
    icon: icons.TbListSearch,
    drawer: true,
    roles: [ UserRoles.SystemAdmin, UserRoles.HeadAdmin ],
}

const booking = {
    path: 'booking',
    title: '場地預約',
    children: [ ],
}

export default booking;