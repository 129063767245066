import { useMemo } from 'react';
import {
    Grid,
    Button,
    InputLabel,
    Stack,
} from '@mui/material';

export default function ListActionButton({
    identifier,
    xs,
    index,
    buttonList,
    handler,
    currentIndex,
    withLabel,
    totalLength,
}) {
    const actionButtonList = useMemo(() => {
        if (!buttonList) return false;
        let buttons = [];
        for (let i = 0; i < buttonList.length; i++) {
            if (buttonList[i] === 'up' && index === 0) continue;
            if (buttonList[i] === 'down' && index === totalLength - 1) continue;

            let displayText = buttonList[i];
            let handlerIdentifier = buttonList[i];
            switch (buttonList[i]) {
                case 'up':
                    displayText = '上移';
                    break;
                case 'down':
                    displayText = '下移';
                    break;
                case 'copy':
                    displayText = '複製';
                    break;
            }
            buttons.push(<Button
                style={{
                    marginRight: '2px',
                }}
                key={`${identifier}-${index}-buttonList-${i}`}
                onClick={() => handler(handlerIdentifier, index)}
                variant='outlined'
            >
                {displayText}
            </Button>)
        }


        return buttons;
    }, [buttonList, handler, identifier, index, totalLength]);

    return (
        <Grid
            item
            xs={xs}
            key={`${identifier}-grid-${index}`}
        >
            <Stack spacing={3} style={{ marginTop: 5 }}>
                {withLabel && (<InputLabel html={identifier}>行動</InputLabel>)}
                <Stack direction={'row'}>
                    {actionButtonList}
                    <Button
                        color='error'
                        key={`${identifier}-header-${index}`}
                        onClick={() => handler('remove', index)}
                        variant="outlined"
                    >刪除</Button>
                </Stack>
            </Stack>
        </Grid>
    )
}