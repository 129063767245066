import {
    Grid,
    Stack,
    InputLabel,
    FormHelperText,
    TextField,
} from '@mui/material';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

export default function DateField({
    id,
    identifier,
    xs,
    label,
    value,
    subtitle,
    monthOnly,
    touched,
    errorMessage,
    handleBlur,
    handleChange,
}) {
    const views = monthOnly ? ['year', 'month'] : ['year', 'month', 'day'];
    const inputFormat = monthOnly ? 'MM/YYYY' : 'DD/MM/YYYY';
    return (
        <Grid item xs={xs}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack spacing={1}>
                    <InputLabel html={identifier}>{label}</InputLabel>
                    {subtitle && (<InputLabel>{subtitle}</InputLabel>)}
                    <MobileDatePicker
                        views={views}
                        id={identifier}
                        value={value}
                        onBlur={handleBlur}
                        onChange={val => {
                            handleChange(val)
                        }}
                        inputFormat={inputFormat}
                        fullWidth
                        renderInput={(params) => <TextField
                            {...params}
                            sx={{
                                backgroundColor: 'white'
                            }}
                            error={Boolean(touched && errorMessage)}
                        />}

                    />
                    {touched && errorMessage && (
                        <FormHelperText error id={`helper-text-${identifier}`}>
                            {errorMessage}
                        </FormHelperText>
                    )}
                </Stack>
            </LocalizationProvider>
        </Grid>
    )
}