// project import
import './App.css'

import Routes from 'routes';
import ThemeCustomization from 'themes';
import ScrollTop from 'components/ScrollTop';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'


const App = () => {
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
            },
        },
    });

    return (<ThemeCustomization>
        <QueryClientProvider client={queryClient}>
            <ScrollTop>
                <Routes />
            </ScrollTop>
        </QueryClientProvider>
    </ThemeCustomization>)
}

export default App;
