import { 
    Grid,
    Stack,
    InputLabel,
    FormHelperText,
} from '@mui/material';
import { useField, useFormikContext } from 'formik';
import AsyncSelect from 'react-select/async';

export default function AsyncSelectField({
    id,
    identifier,
    xs,
    label,
    subtitle,
    requestService,
    parseFunction,
    handleBlur,
    isMulti,
    touched,
    value,
    errorMessage,
    detectChange,
    handleChange,
}) {
    const fetchData = async (resolve, inputValue) => {
        const data = await requestService(inputValue);
        const list = parseFunction(data, inputValue, isMulti);

        resolve(list);
    }

    const loadOptions = (inputValue) => 
        new Promise((resolve) => {
            return fetchData(resolve, inputValue);
        })

    const onChangeHandler = (val) => {
        handleChange(val);
    }

    return (
        <Grid item xs={xs}>
            <Stack spacing={1}>
                <InputLabel html={identifier}>{label}</InputLabel>
                {subtitle && (<InputLabel>{subtitle}</InputLabel>)}
                <AsyncSelect
                    cacheOptions
                    defaultOptions
                    loadOptions={loadOptions}
                    value={value}
                    isMulti={isMulti}
                    onBlur={handleBlur}
                    onChange={onChangeHandler}
                    placeholder="請選擇"
                    styles={{
                        control: (base, state) => ({
                            ...base,
                            borderColor: Boolean(errorMessage) ? 'red': 'lightgray',
                        })
                    }}
                />
                {errorMessage && (
                    <FormHelperText error id={`helper-text-${identifier}`}>
                        {errorMessage}
                    </FormHelperText>
                )}
            </Stack>
        </Grid>
    )
}