import { lazy } from 'react';
import Loadable from 'components/Loadable';

const CoachReport = Loadable(lazy(() => import('pages/report/CoachHours')));

import { BiTime } from 'react-icons/bi';

// icons
const icons = {
    BiTime
};

import constant from '../utils/constant';
const UserRoles = constant.UserRoles;

const coachHourReport = {
    id: 'HourReport',
    title: '教練時數報告',
    path: 'coachhourreport',
    page: <CoachReport />,
    icon: icons.BiTime,
    drawer: true,
    roles: [UserRoles.HeadAdmin],
}

const setting = {
    path: 'Report',
    title: '報告',
    children: [coachHourReport],
}

export default setting;