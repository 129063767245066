import { useMemo } from 'react';

// material-ui
import {
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    TextField,
} from '@mui/material';

import { MdEdit, MdDeleteForever } from 'react-icons/md';

import constant from 'utils/constant';

function OrderTableHead({ headers, containsAction }) {
    return (
        <TableHead>
            <TableRow>
                {headers.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.align}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                    >
                        {headCell.label}
                    </TableCell>
                ))}
                {containsAction && (
                    <TableCell
                        key={'action'}
                        align={'center'}
                        padding={'none'}
                    >
                        行動
                    </TableCell>
                )}
            </TableRow>
        </TableHead>
    );
}

export default function BaseTable({
    headers,
    items,
    onUpdate,
    onDelete,
    eventHandlerList,
    page,
    rowsPerPage,
    totalCount,
    pageChange,
    noFooter,
    value,
    onChangeHandler,
}) {
    const containsAction = useMemo(() => {
        if (onUpdate || onDelete) {
            return true;
        }
        if (eventHandlerList && eventHandlerList.length !== 0) {
            return true;
        }
        return false;
    }, [onUpdate, onDelete, eventHandlerList]);

    const handleChangePage = (event, newPage) => {
        pageChange(newPage);
    };

    const getItemFromKey = (row, item) => {
        const id = item.id;

        if (item.type === 'numberinput' || item.type === 'textinput') {
            const itemIdentifier = row[item.itemIdentifier];
            const currentItemValue = value ? (value[itemIdentifier] ?? {}) : {};
            return (<TextField
                value={currentItemValue[id] ?? ''}
                onChange={(e) => onChangeHandler(row, item, e.target.value)}
                variant={'outlined'}
                type={item.type === 'numberinput' ? 'number' : 'text'}
            />)
        }

        const idList = id.split('.');

        try {
            let current = row;
            for (let i = 0; i < idList.length; i++) {
                if (current) {
                    current = current[idList[i]];
                }
            }

            return current;
        } catch (e) {
            console.log(e);
        }

        return row[id];
    }

    return (
        <Box>
            <TableContainer
                sx={{
                    width: '100%',
                    overflowX: 'auto',
                    position: 'relative',
                    display: 'block',
                    maxWidth: '100%',
                    '& td, & th': { whiteSpace: 'nowrap' }
                }}
            >
                <Table
                    aria-labelledby="tableTitle"
                    sx={{
                        '& .MuiTableCell-root:first-of-type': {
                            pl: 2
                        },
                        '& .MuiTableCell-root:last-of-type': {
                            pr: 3
                        }
                    }}
                >
                    <OrderTableHead
                        headers={headers}
                        containsAction={containsAction}
                    />
                    <TableBody>
                        {items.map((row, index) => {
                            const labelId = `tableKey-${index}`;

                            return (
                                <TableRow
                                    hover
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    tabIndex={-1}
                                    key={labelId}
                                >
                                    {headers.map((item, headerindex) => {
                                        const itemId = `table-${index}-item-${headerindex}`;

                                        return (
                                            <TableCell
                                                align={item.align}
                                                key={itemId}
                                            >
                                                {getItemFromKey(row, item)}
                                            </TableCell>
                                        )
                                    })}
                                    {containsAction && (
                                        <TableCell
                                            align={'center'}
                                        >
                                            {onUpdate && (
                                                <Button 
                                                    style={{ marginRight: '5px' }}
                                                    variant='outlined'
                                                    startIcon={<MdEdit />}
                                                    onClick={() => onUpdate(row)}
                                                >編輯</Button>
                                            )}
                                            {onDelete && (
                                                <Button
                                                    style={{ color: 'red', marginRight: '5px' }}
                                                    variant='outlined'
                                                    startIcon={<MdDeleteForever />}
                                                    onClick={() => onDelete(row)}
                                                >刪除 </Button>
                                            )}
                                            {eventHandlerList && eventHandlerList.length !== 0 && eventHandlerList.map((eventItem, eventIndex) => {
                                                let shouldShow = false;
                                                if (!eventItem.condition) {
                                                    shouldShow = true;  // if there is no condition, then we should show this item
                                                } else {
                                                    shouldShow = eventItem.condition(row);
                                                }
                                                
                                                if (!shouldShow) {
                                                    return false;
                                                }

                                                if (eventItem.icon && !eventItem.label) {
                                                    return (
                                                        <Button
                                                            key={`table-${index}-event-${eventIndex}`}
                                                            style={{ color: eventItem.color, marginRight: '5px' }}
                                                            variant='outlined'
                                                            onClick={() => eventItem.handler(row)}
                                                        >
                                                            {eventItem.icon}
                                                        </Button>
                                                    )
                                                }

                                                let label = eventItem.label;
                                                if ((typeof eventItem.label) === 'function') {
                                                    label = eventItem.label(row);
                                                }
                                                
                                                return (
                                                    <Button
                                                        key={`table-${index}-event-${eventIndex}`}
                                                        style={{ color: eventItem.color, marginRight: '5px' }}
                                                        variant='outlined'
                                                        startIcon={eventItem.icon}
                                                        onClick={() => eventItem.handler(row)}
                                                    >
                                                        {label}
                                                    </Button>
                                                )
                                            })}
                                        </TableCell>
                                    )}
                                    {/* <TableCell align="left">{item.name}</TableCell> */}
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            {!noFooter && (
                <>
                    {pageChange ? (
                        <TablePagination
                            rowsPerPageOptions={[constant.rowsPerPage]}
                            component="div"
                            rowsPerPage={rowsPerPage ? rowsPerPage :constant.rowsPerPage}
                            count={totalCount}
                            page={page}
                            onPageChange={handleChangePage}
                        />
                    ) : (
                        <div>{totalCount} record(s) in total</div>
                    )}
                </>
            )}
            
            
        </Box>
    )
}