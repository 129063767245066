import { useMemo } from 'react';
import {
    Grid,
} from '@mui/material';

export default function ComponentField({
    id,
    components,
    xs
}) {
    const component = useMemo(() => {
        if (components && components[id]) {
            return components[id];
        }
        return false;
    }, [components, id]);

    return (
        <Grid item xs={xs}>
            {component}
        </Grid>
    )
}