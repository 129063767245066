import { 
    Grid,
    Stack,
    InputLabel,
    FormHelperText,
} from '@mui/material';
import Select from 'react-select';

export default function SelectField({
    id,
    identifier,
    xs,
    label,
    value,
    subtitle,
    isMulti,
    options,
    touched,
    errorMessage,
    handleChange,
}) {

    return (
        <Grid item xs={xs}>
            <Stack spacing={1}>
                <InputLabel html={identifier}>{label}</InputLabel>
                {subtitle && (<InputLabel>{subtitle}</InputLabel>)}
                <Select 
                    options={options}
                    value={value}
                    isMulti={isMulti}
                    onChange={handleChange}
                    styles={{
                        control: (base, state) => ({
                            ...base,
                            borderColor: Boolean(touched && errorMessage) ? 'red': 'lightgray',
                        }),
                        menuPortal: provided => ({ ...provided, zIndex: 9999 }),
                        menu: provided => ({ ...provided, zIndex: 9999 })
                    }}
                />
                {touched && errorMessage && (
                    <FormHelperText error id={`helper-text-${identifier}`}>
                        {errorMessage}
                    </FormHelperText>
                )}
            </Stack>
        </Grid>
    )
}