import { lazy } from 'react';
import Loadable from 'components/Loadable';

import { FaList } from 'react-icons/fa';
import { GoGitPullRequest } from 'react-icons/go';
import { MdOtherHouses, MdQueue, MdOutlinePublishedWithChanges, MdApproval } from 'react-icons/md';

const icons = {
    FaList,
    MdOtherHouses,
    MdQueue,
    MdOutlinePublishedWithChanges,
    MdApproval,
}

const ChangeRequestList = Loadable(lazy(() => import('pages/changerequest/ChangeRequestList')));
const ChangeRequestApprovalList = Loadable((lazy(() => import('pages/changerequest/ChangeRequestApprovalList'))))
import constant from '../utils/constant';
const UserRoles = constant.UserRoles;

const changeRequestList = {
    id: 'changerequest-list',
    title: '申請',
    path: 'list',
    page: <ChangeRequestList />,
    icon: icons.MdOutlinePublishedWithChanges,
    drawer: true,
    roles: [UserRoles.SystemAdmin, UserRoles.AssoAdmin, UserRoles.Coach, UserRoles.CoachAssitant],
};
const changeRequestApprovalList = {
    id: 'changerequestapporval-list',
    title: '待辦審批事項',
    path: 'approvallist',
    page: <ChangeRequestApprovalList />,
    icon: icons.MdApproval,
    drawer: true,
    roles: [UserRoles.HeadAdmin, UserRoles.AssoAdmin],
};
const submittedChangeRequestList = {
    id: 'submitchangerequest-list',
    title: '已提交審批事項',
    path: 'submittedchangerequest',
    page: <ChangeRequestApprovalList />,
    icon: GoGitPullRequest,
    drawer: true,
    roles: [UserRoles.AssoAdmin, UserRoles.Coach, UserRoles.CoachAssitant],
};
const association = {
    path: 'changerequest',
    title: '資料更改申請',
    children: [changeRequestList, changeRequestApprovalList, submittedChangeRequestList],
}

export default association;