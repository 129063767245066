import axios from 'axios';
import toastHelper from 'utils/toastHelper';
import constant from 'utils/constant';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const deleteCookies = (name) => {
    cookies.remove(name, { path: '/' });
}

const axiosConfig = {
    baseURL: process.env.REACT_APP_BACKEND_API,
    timeout: 30000,
}

const request = axios.create(axiosConfig);

request.interceptors.request.use(
    config => {
        const userInfo = cookies.get(constant.USERINFO, { path: '/' });
        if (userInfo) {
            if (userInfo && userInfo.sessionKey) {
                config.headers.Authorization = userInfo.sessionKey;
            }
        }

        return config;
    },
    error => {
        Promise.reject(error);
    }
);

request.interceptors.response.use(
    response => {
        return response
    },
    function (error) {
        if (error.response) {
            if (error.response.status === 401) {
                deleteCookies(constant.USERINFO);
                toastHelper.error("登入逾時, 請重新登入");
                //window.location.replace(constant.loginPage);
            } else if (error.response.status === 403) {
                toastHelper.error("你沒有足夠權限存取此功能");
            }
        }
        return Promise.reject(error);
    }
);

export default request;