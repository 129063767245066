import { useState, useMemo, useEffect } from 'react';
import { useQuery, useMutation } from "@tanstack/react-query";
import { useLocation, useNavigate } from "react-router-dom";
import {
    Grid,
    Typography,
    Button,
} from '@mui/material';

import { MdAdd } from 'react-icons/md';
import { AiFillSave } from 'react-icons/ai';

import MainCard from 'components/MainCard';
import BaseTable from 'components/material/BaseTable';
import SearchBar from 'components/material/SearchBar';
import Loader from 'components/Loader';
import MemberShipYearService from 'services/membershipYear';

import toastHelper from 'utils/toastHelper';
import constant from 'utils/constant';
//import faker from 'utils/faker';
import { getDefaultByList, makeQueryString, getDefaultPage } from 'utils/helper/url';
import MemberShipYearForm from './form/MemberShipYearForm';

const tableHeaders = [
    {
        id: 'fromYear',
        align: 'left',
        disablePadding: false,
        label: '開始年份'
    },
    {
        id: 'toYear',
        align: 'left',
        disablePadding: false,
        label: '完結年份'
    },
]

const searchFieldList = []

const MemberShipYearList = () => {
    const navigate = useNavigate();
    const search = useLocation().search;
    const defaultParams = getDefaultByList(searchFieldList, search);

    const [searchingKey, setSearchingKey] = useState(defaultParams);

    const [page, setPage] = useState(getDefaultPage(search));
    const [openForm, setOpenForm] = useState(false);
    const [editingItem, setEditingItem] = useState(null);
    const { data: years, isLoading: isLoadingYear, refetch } = useQuery(["page", page], () =>
        MemberShipYearService.list({ page: page + 1, pageSize: constant.rowsPerPage, allowPage: true })
    );

    const [orderingValue, setOrderingValue] = useState({});

    const yearInfo = useMemo(() => {
        const result = {
            items: [],
            count: 0,
        }
        if (isLoadingYear) {
            return result;
        }

        try {
            const content = years?.data?.content;
            const count = years?.data?.totalcount;
            result.items = content;
            result.count = count;
        } catch (e) {
            console.log(e);
        }

        return result;
    }, [isLoadingYear, years]);

    const yearItems = useMemo(() => {
        return yearInfo.items;
    }, [yearInfo]);


    const handleChangePage = (newPage) => {
        setPage(newPage);
        const search = _.clone(searchingKey);
        search.page = newPage;

        const query = makeQueryString(search);

        navigate({
            pathname: window.location.pathname,
            search: query,
        })
    };


    const onUpdateHandler = (item) => {
        setEditingItem(item);
        setOpenForm(true);
    }


    const onYearUpdated = () => {
        setEditingItem(null);
        setOpenForm(false);
        refetch();
    }

    const onTableValueChangeHandler = (row, item, newValue) => {
        const newList = { ...orderingValue };
        const itemIdentifier = row[item.itemIdentifier];
        newList[itemIdentifier][item.id] = newValue;
        setOrderingValue(newList);
    }

    return (
        <>
            <Grid container rowSpacing={4.5} columnSpacing={2.75}>
                <Grid item xs={12} md={12} lg={12}>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item xs={10}>
                            <Typography variant="h5">續會年份</Typography>
                        </Grid>
                    </Grid>
                    {isLoadingYear ? (
                        <Loader />
                    ) : (
                        <MainCard sx={{ mt: 2 }} content={false}>
                            <BaseTable
                                headers={tableHeaders}
                                items={yearItems}
                                onUpdate={onUpdateHandler}
                                pageChange={handleChangePage}
                                noFooter={true}
                                value={orderingValue}
                                onChangeHandler={onTableValueChangeHandler}
                            />
                        </MainCard>
                    )}

                </Grid>
            </Grid>
            <MemberShipYearForm
                open={openForm}
                handleclose={() => setOpenForm(false)}
                editingYear={editingItem}
                onYearUpdated={onYearUpdated}
            />
        </>
    )
};

export default MemberShipYearList;