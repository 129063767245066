import { lazy } from 'react';
import Loadable from 'components/Loadable';

import { FaList } from 'react-icons/fa';
import { MdQueue } from 'react-icons/md';

const icons = {
    FaList,
    MdQueue,
}

const AssociationList = Loadable(lazy(() => import('pages/association/AssociationList')));
const PendingAssociation = Loadable(lazy(() => import('pages/association/PendingAssociation')));

import constant from '../utils/constant';
const UserRoles = constant.UserRoles;

const associationList = {
    id: 'association-list',
    title: '列表',
    path: 'list',
    page: <AssociationList />,
    icon: icons.FaList,
    drawer: true,
    roles: [ UserRoles.SystemAdmin, UserRoles.HeadAdmin ],
};

const pendingAssociation = {
    id: 'pending-association',
    title: '待審批',
    path: 'pending',
    page: <PendingAssociation />,
    icon: icons.MdQueue,
    drawer: true,
    roles: [UserRoles.SystemAdmin, UserRoles.HeadAdmin ],
}

const association = {
    path: 'association',
    title: '屬會',
    children: [ associationList ],
}

export default association;