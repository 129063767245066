import { useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import Cookies from 'universal-cookie';

// project import
import NavGroup from './NavGroup';
import { getDrawItems } from 'menu-items';

import constant from 'utils/constant';

const cookies = new Cookies();

const Navigation = () => {

    const menuItem = useMemo(() => {
        const userInfo = cookies.get(constant.USERINFO, { path: '/' }); 
        if (!userInfo) return null;
        const items = getDrawItems(userInfo.userrole);
        return items;
    }, []);

    const navGroups = menuItem ? menuItem.items.map((item) => {
        return <NavGroup key={item.id} item={item} />;
    }) : (<></>);

    return <Box sx={{ pt: 2 }}>{navGroups}</Box>;
};

export default Navigation;
