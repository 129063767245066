import { useState } from 'react';

import { Formik } from 'formik';
import { Grid } from '@mui/material';

import BaseDialog from './BaseDialog';
import DynamicField from 'components/field/DynamicField';

export default function BaseForm({
    open,
    handleclose,
    identifier,
    fieldList,
    title,
    confirmText,
    components,
    initialValues,
    validationSchema,
    onSubmitHandler,
}) {
    const [ submittedOnce, setSubmittedOnce ] = useState(false);

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validateOnBlur={submittedOnce}
            validateOnChange={false}
            validationSchema={validationSchema}
            onSubmit={onSubmitHandler}
        >
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => {
                const getValues = (id) => {
                    return values[id];
                }
                return (
                    <BaseDialog
                        open={open}
                        handleclose={handleclose}
                        title={title}
                        confirmText={confirmText}
                        onConfirm={() => {
                            setSubmittedOnce(true);
                            handleSubmit();
                        }}
                        isLoading={isSubmitting}
                    >
                        <Grid container spacing={3}>
                            {fieldList.map((field, index) => {
                                if (field.referenceId) {
                                    return (
                                        <DynamicField
                                            {...field}
                                            key={`${field.id}-${identifier}`}
                                            identifier={`${field.id}-${identifier}`}
                                            value={getValues(field.referenceId)}
                                            touched={touched[field.id]}
                                            errorMessage={errors[field.id]}
                                            handleBlur={handleBlur}
                                            handleChange={handleChange}
                                            components={components}
                                        />
                                    )
                                }
                                return (
                                    <DynamicField
                                        {...field}
                                        key={`${field.id}-${identifier}`}
                                        identifier={`${field.id}-${identifier}`}
                                        value={values[field.id]}
                                        touched={touched[field.id]}
                                        errorMessage={errors[field.id]}
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        components={components}
                                    />
                                )
                            })}
                        </Grid>
                    </BaseDialog>
                )
            }}
        </Formik>
    )
}
