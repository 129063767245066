import { lazy } from 'react';
import Loadable from 'components/Loadable';

const SportTypeList = Loadable(lazy(() => import('pages/setting/SportTypeList')));
const BookingVenuesList = Loadable(lazy(() => import('pages/setting/BookingVenuesList')));
const SendEmailList = Loadable(lazy(() => import('pages/setting/SendEmail')));


// assets
import { MdOutlineSportsHandball, MdOutgoingMail } from 'react-icons/md';
import { TbBuildingCommunity, TbCalendar } from 'react-icons/tb';

// icons
const icons = {
    MdOutlineSportsHandball,
    TbBuildingCommunity,
    MdOutgoingMail,
    TbCalendar
};

import constant from '../utils/constant';
import MemberShipYearList from 'pages/setting/MemberShipYear';
const UserRoles = constant.UserRoles;

const sportTypeManage = {
    id: 'sportTypeManage',
    title: '開辦課程種類',
    path: 'sporttypelist',
    page: <SportTypeList />,
    icon: icons.MdOutlineSportsHandball,
    drawer: true,
    roles: [UserRoles.HeadAdmin],
}

const bookingVenuesList = {
    id: 'venues-list',
    title: '場地列表',
    path: 'venueslist',
    page: <BookingVenuesList />,
    icon: icons.TbBuildingCommunity,
    drawer: true,
    roles: [UserRoles.HeadAdmin],
};


const memberShipYearList = {
    id: 'membershipyear-list',
    title: '續會年份',
    path: 'membershipyearlist',
    page: <MemberShipYearList />,
    icon: icons.TbCalendar,
    drawer: true,
    roles: [UserRoles.HeadAdmin],
};

const massEmail = {
    id: 'email-list',
    title: '發送電郵',
    path: 'massemail',
    page: <SendEmailList />,
    icon: icons.MdOutgoingMail,
    drawer: true,
    roles: [UserRoles.HeadAdmin],
};

const setting = {
    path: 'setting',
    title: '系統設定',
    //children: [sportTypeManage, bookingVenuesList, memberShipYearList, massEmail],
    children: [memberShipYearList],
}

export default setting;