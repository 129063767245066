import {
    Grid,
    Stack,
    InputLabel,
    FormHelperText,
    TextField,
} from '@mui/material';
import { useField, useFormikContext } from 'formik';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDateTimePicker  } from '@mui/x-date-pickers/MobileDateTimePicker';

export default function DateTimeField({
    id,
    identifier,
    xs,
    label,
    subtitle,
    touched,
    errorMessage,
    handleBlur,
}) {
    const { setFieldValue } = useFormikContext();
    const [ field ] = useField({ name: id });

    return (
        <Grid item xs={xs}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack spacing={1}>
                    <InputLabel html={identifier}>{label}</InputLabel>
                    {subtitle && (<InputLabel>{subtitle}</InputLabel>)}
                    <MobileDateTimePicker
                        id={identifier}
                        value={field.value}
                        onBlur={handleBlur}
                        onChange={val => {
                            setFieldValue(field.name, val)
                        }}
                        inputFormat="DD/MM/YYYY hh:mm a"
                        fullWidth
                        renderInput={(params) => <TextField 
                            {...params} 
                            error={Boolean(touched && errorMessage)}
                        />}
                        
                    />
                    {touched && errorMessage && (
                        <FormHelperText error id={`helper-text-${identifier}`}>
                            {errorMessage}
                        </FormHelperText>
                    )}
                </Stack>
            </LocalizationProvider>
        </Grid>
    )
}