import React from 'react'
import {
    Grid,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Slide,
} from '@mui/material'

const TransitionFromLeft = (props) => {
    return <Slide {...props} direction='right' />
}

export default function PdfPreview({
    shouldOpen,
    handleClose,
    fileInfo,
}) {
    const getPreviewFile = () => {
        if (fileInfo) {
            if (fileInfo.type === 'uploaded') {
                const url = URL.createObjectURL(fileInfo.file)
                return url
            } else if (fileInfo.type === 'server') {
                const url = process.env.REACT_APP_BACKEND_API + '/' + fileInfo.file
                return url

            }
        }
        return false
    }
    return (
        <Dialog open={shouldOpen} onClose={handleClose} TransitionComponent={TransitionFromLeft}>
            <DialogTitle>PDF Preview</DialogTitle>
            <DialogContent>
                <iframe
                    src={getPreviewFile()}
                    title='PDF Preview'
                    style={{ width: '100%', height: '500px' }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}