// material-ui
import { Box, IconButton, Link, useMediaQuery } from '@mui/material';
import { GithubOutlined } from '@ant-design/icons';

// project import
import Search from './Search';
import Profile from './Profile';
import Notification from './Notification';
import MobileSection from './MobileSection';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
    const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'));

    return (
        <>
            {!matchesXs && <Profile placement={'bottom-start'} />}
            {/* {!matchesXs && <Search />} */}

            {matchesXs && <Box sx={{ width: '100%', ml: 1 }} />}

            {/* <Notification /> */}
            {/* {!matchesXs && <Profile placement={'bottom-end'}/>} */}
            {matchesXs && <MobileSection />}
        </>
    );
};

export default HeaderContent;
