import { forwardRef } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Typography,
  AppBar,
  Toolbar,
  Slide,
  IconButton,
} from '@mui/material';

import { IoClose } from 'react-icons/io5';

const TransitionUp = forwardRef((props, ref) => {
  return <Slide {...props} ref={ref} direction='up' />
});

export default function BaseDialog({
  open,
  handleclose,
  title,
  confirmText,
  onConfirm,
  isLoading,
  children,
}) {
  return (
    <Dialog
      fullScreen
      open={open}
      scroll={'paper'}
      TransitionComponent={TransitionUp}
    >
      <DialogTitle>
        <AppBar>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleclose}
              aria-label="close"
            >
              <IoClose />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {title}
            </Typography>
            {confirmText ?
              <Button
                color="inherit"
                onClick={onConfirm}
                disabled={isLoading}
              >
                {confirmText}
              </Button>
              : null}
          </Toolbar>
        </AppBar>
      </DialogTitle>
      <Box sx={{ p: 3, mt: 5 }}>
        {children}
      </Box>
    </Dialog>
  )
}