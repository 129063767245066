import Logo from './chkmcu.png';

const CompanyLogo = ({
    size,
}) => {
    return (
        <img src={Logo} alt={'CHKMCU'} width={`${207 * size}px`} height={`${180 * size}px`}/>
    )
}

export default CompanyLogo;