import { useState } from 'react'
import {
    Grid,
    FormControl,
    FormLabel,
    InputLabel,
    Button,
    Stack,
} from '@mui/material';
import { useField, useFormikContext } from 'formik';

import { AiOutlineUpload } from 'react-icons/ai';
import { MdVisibility } from 'react-icons/md';

import ImagePreview from 'components/field/ImagePreview';
import PdfPreview from 'components/field/PdfPreview';

export default function ImageField({
    id,
    identifier,
    xs,
    label,
    subtitle,
    handleBlur,
    isMulti,
    options,
    touched,
    allowPdf,
    errorMessage,
}) {
    const { setFieldValue } = useFormikContext();
    const [field] = useField({ name: id });

    const [openPreview, setOpenPreview] = useState(false)

    const isFileValid = (file) => {
        if (file.type.startsWith('image/')) {
            return true;
        }
        if (allowPdf && file.type === 'application/pdf') {
            return true;
        }
        return false;
    }

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (!file) {
            return;
        }
        if (isFileValid(file)) {
            setFieldValue(field.name, {
                type: 'uploaded',
                message: `已上傳 ${file.name}`,
                file: file,
            });
        } else {
            // Optional: Add error handling for non-image files
            alert('請上傳圖片檔案');
        }
    }

    const getExtension = (file) => {
        if (!file) {
            return false;
        }
        if (file.type === 'uploaded') {
            const extension = file.file.name.split('.').pop();
            return extension;
        } else if (file.type === 'server') {
            const extension = file.file.split('.').pop();
            return extension;
        }
        return false;
    }

    return (
        <Grid item xs={xs}>
            <Stack spacing={1}>
                <InputLabel>{label}</InputLabel>
                <FormControl component='image' fullWidth>
                    <FormLabel>
                        {field.value?.message}
                    </FormLabel>
                    <Grid container spacing={0} fullWidth>
                        <Grid item xs={6}>
                            <Button
                                variant='outlined'
                                component='label'
                                fullWidth>
                                <input type='file' id={`upload-image-${id}`} style={{ display: 'none' }} onChange={handleImageChange} accept={allowPdf ? 'image/*,application/pdf' : 'image/*'} />
                                <AiOutlineUpload
                                    size={22}
                                    style={{ color: 'blue' }}
                                />
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                variant='outlined'
                                fullWidth
                                disabled={!field.value}
                                onClick={() => setOpenPreview(true)}
                            >
                                <MdVisibility
                                    size={22}
                                    style={{ color: 'blue' }}
                                />
                            </Button>
                        </Grid>
                    </Grid>
                </FormControl>
            </Stack>
            {field.value && (
                <>
                    {getExtension(field?.value) === 'pdf' ? (
                        <PdfPreview
                            shouldOpen={openPreview}
                            handleClose={() => setOpenPreview(false)}
                            fileInfo={field.value}
                        />
                    ) : (
                        <ImagePreview
                            shouldOpen={openPreview}
                            handleClose={() => setOpenPreview(false)}
                            imageInfo={field.value}
                        />
                    )
                    }
                </>)}
        </Grid>
    )
}