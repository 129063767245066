import { 
    Grid,
    Stack,
    InputLabel,
    FormHelperText,
} from '@mui/material';
import { useField, useFormikContext } from 'formik';
import AsyncSelect from 'react-select/async';
import useAuthentication from 'hooks/useAuthentication';
import constant from 'utils/constant';
import { useMemo, useEffect } from 'react';

export default function AsyncSelectField({
    id,
    identifier,
    xs,
    label,
    subtitle,
    requestParams,
    requestService,
    parseFunction,
    handleBlur,
    listOptions,
    defaultValue,
    isMulti,
    errorMessage,
    detectChange,
}) {
    const{ values, setFieldValue } = useFormikContext();
    const [ field ] = useField({ name: id });

    const [username, isSuperRole, isCoachRole, userId, role, currentUserInfo] = useAuthentication();

    const optionKey = useMemo(() => {
        if (!requestParams) {
            return null;
        }
        const currentRequesting = []
        for (let i = 0; i < requestParams.length; i++) {
            const param = requestParams[i];
            currentRequesting.push(values[param]);
        }
        return JSON.stringify(currentRequesting);
    }, [requestParams, values])

    const fetchData = async (resolve, inputValue) => {
        const currentRequesting = []
        if (requestParams) {
            for (let i = 0; i < requestParams.length; i++) {
                const param = requestParams[i];
                currentRequesting.push(values[param]);
            }
        }
        const data = await requestService(inputValue, ...currentRequesting);
        const list = parseFunction(data, inputValue, isMulti);

        if (defaultValue) {
            let selectedValue = null;
            if (defaultValue === constant.DefaultValueType.Association) {
                const association_id = currentUserInfo.associationid;
                const association_name = currentUserInfo.associationname;
                selectedValue = { label: association_name, value: association_id };
            } else if (defaultValue === constant.DefaultValueType.AssocitaionForAssoRelated) {
                if (role === constant.UserRoles.AssoAdmin || role === constant.UserRoles.Coach) {
                    const association_id = currentUserInfo.associationid;
                    const association_name = currentUserInfo.associationname;
                    selectedValue = { label: association_name, value: association_id };
                }
            }
            
            if (selectedValue) {
                if (!(field && field.value && field.value.value)) {
                    // if the value is currently null, which means that it should be override by default value
                    setFieldValue(field.name, selectedValue);
                }
    
                if (listOptions === constant.FormValueType.ListOptions.FixedWhenDefaultValueExists) {
                    resolve([selectedValue]);
                    return;
                }
            }
        }

        if (isMulti) {
            const newValue = [];
            if (field.value && field.value.length > 0) {
                for (let i = 0; i < field.value.length; i++) {
                    const value = field.value[i];
                    const found = list.find((item) => item.value === value.value);
                    if (found) {
                        newValue.push(found);
                    }
                
                }
            }
            onChangeHandler(newValue);
        } else {
            if (field.value && field.value.value) {
                const found = list.find((item) => item.value === field.value.value);
                if (found) {
                    onChangeHandler(found);
                } else {
                    onChangeHandler(null);
                }
            }
        }

        resolve(list);
    }

    const loadOptions = (inputValue) => 
        new Promise((resolve) => {
            return fetchData(resolve, inputValue);
        })

    const onChangeHandler = (val) => {
        detectChange && detectChange(setFieldValue, field.name, val);
        setFieldValue(field.name, val);
    }

    return (
        <Grid item xs={xs}>
            <Stack spacing={1}>
                <InputLabel html={identifier}>{label}</InputLabel>
                {subtitle && (<InputLabel>{subtitle}</InputLabel>)}
                <AsyncSelect
                    key={optionKey}
                    cacheOptions
                    defaultOptions
                    loadOptions={loadOptions}
                    value={field.value}
                    isMulti={isMulti}
                    onBlur={handleBlur}
                    onChange={onChangeHandler}
                    placeholder="請選擇"
                    styles={{
                        control: (base, state) => ({
                            ...base,
                            borderColor: Boolean(errorMessage) ? 'red': 'lightgray',
                        })
                    }}
                />
                {errorMessage && (
                    <FormHelperText error id={`helper-text-${identifier}`}>
                        {errorMessage}
                    </FormHelperText>
                )}
            </Stack>
        </Grid>
    )
}