import { useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';

import { isSuper, isCoach, isAssoAdmin } from 'utils/helper/role';

import constant from 'utils/constant';

const cookies = new Cookies();

function useAuthentication() {
    const navigate = useNavigate();

    const currentUserInfo = useMemo(() => {
        const userInfo = cookies.get(constant.USERINFO, { path: '/' });
        return userInfo;
    }, []);

    const username = useMemo(() => {
        if (currentUserInfo) {
            return currentUserInfo.username;
        }
        return '<Username>';
    }, [currentUserInfo]);

    const isSuperRole = useMemo(() => {
        if (currentUserInfo) {
            return isSuper(currentUserInfo.userrole);
        }
        return false;
    }, [currentUserInfo]);

    const isCoachRole = useMemo(() => {
        if (currentUserInfo) {
            return isCoach(currentUserInfo.userrole);
        }
        return false;
    }, [currentUserInfo]);

    const isAssoAdminRole = useMemo(() => {
        if (currentUserInfo) {
            return isAssoAdmin(currentUserInfo.userrole);
        }
        return false;
    }, [currentUserInfo]);

    const userId = useMemo(() => {
        if (currentUserInfo) {
            return currentUserInfo.userid;
        }
        return false;
    }, [currentUserInfo]);
    const role = useMemo(() => {
        if (currentUserInfo) {
            return currentUserInfo.userrole;
        }
        return false;
    }, [currentUserInfo]);

    return [username, isSuperRole, isCoachRole, userId, role, currentUserInfo, isAssoAdminRole];
}

export default useAuthentication;