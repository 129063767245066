const constant = {
    isDevelopment: process.env.REACT_APP_ENVIRONMENT === 'development',
    AUTHORIZATION: 'Authorization',
    USERINFO: 'user_info',
    Format:{
        Date: "DD/MM/YYYY",
        DateTime : "DD/MM/YYYY HH:mm:ss"
    },
    UserRoles: {
        SystemAdmin: 'systemadmin',
        HeadAdmin: '總會管理員',
        Coach: '教練',
        NormalMember: '普通會員',
        AssoAdmin: '屬會管理員',
        CoachAssitant: '助教',
        AssoMember: '屬會會員',
    },

    homaPage: '/dashboard/default',
    submittedChangeRequest: '/changerequest/submittedchangerequest',
    managePersonal: '/manage/PersonalInformation',
    changeRequestApprovalPage: '/changerequest/approvallist',
    loginPage: '/login',
    rowsPerPage: 10,
    ChangeRequestType: {
        EditAssociation: "EditAssociation",
        CreateCourse: "CreateCourse",
        ChangeAssociation: "ChangeAssociation",
        UserRole: "ChangeUserRole",
        Participant: "ChangeParticipant",
        RenewCertificate: "RenewCertificate",
        CreateBooking: 'CreateBooking',
    },
    CourseLevel: [
        { label: '一級', value: 1 },
        { label: '二級', value: 2 },
        { label: '三級', value: 3 },
        { label: '其他', value: null },
    ],
    Genders: [
        { label: '男', value: 1 },
        { label: '女', value: 0 },
        { label: '其他', value: 2 },
    ],
    CourseLevelOnlyOthers: [
        { label: '其他', value: null },
    ],
    CourseLevelTranslation: [
        { chinese: '一級', english: 'Level One', value: 1 },
        { chinese: '二級', english: 'Level Two', value: 2 },
        { chinese: '三級', english: 'Level Three', value: 3 },
        { chinese: '其他', english: 'Other', value: null },
        { chinese: '', english: '', value: null },
    ],
    ChangeRequestStatus: {
        NotApproval: 0,
        Approved: 1,
        Rejected: 2,
        Resubmit: 3
    },
    AssociationMemberType: [
        { label: "附屬會員", value: "附屬會員" },
        { label: "普通會員", value: "普通會員" }
    ],
    BookingRequestStatus: {
        Pending: 0,
        Approved: 1,
        Rejected: 2,
    },
    CourseRole: {
        Candidate: 0,
        Assistant: 1,
        Coach: 2,
    },
    CertificateType: {
        CHKMCU_TrainingCert: 0,
        CH_TrainingCert: 1,
        CH_QualificationCert: 2,
        CHKMCU_CoachCert: 3,
        AssitantCard: 4,
        AssistantCard: 5,
    },
    CalendarBookingColour: [
        '#3F51B5',
        '#009688',
        '#FF9800',
        '#795548',
        '#607D8B',
        '#2196F3',
        '#4CAF50',
        '#FF5722',
        '#9C27B0',
        '#03A9F4',
        '#8BC34A',
    ],
    DefaultValueType: {
        Association: 'Association',
        AssocitaionForAssoRelated: 'AssocitaionForAssoRelated',
    },
    FormValueType: {
        ListOptions: {
            FixedWhenDefaultValueExists: 'FixedWhenDefaultValueExists',
        }
    },
    SportTypeCategory: [
        { label: "參與者", value: 0 },
        { label: "助教", value: 1 },
        { label: "教練", value: 2 },
    ],
    AgreementCourseType: [
        { label: '運動攀登 | Sporting Climbing', value: 'sportingClimbing' },
        { label: '山藝 | Mountain Craft', value: 'mountainCraft' },
        { label: '攀岩 | Rock Climbing', value: 'rockClimbing' },
        { label: '繩索技術 | Roping & Abselling', value: 'ropingAbselling' },
        { label: '冰雪攀山 | Snow & Ice Climbing', value: 'snowIceClimbing' },
    ],
};

export default constant;
