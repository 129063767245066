import { useState, useMemo } from 'react';
import {
    Grid,
    OutlinedInput,
    Stack,
    InputLabel,
} from '@mui/material';
import { useField, useFormikContext } from 'formik';
import { SketchPicker } from 'react-color';

const styles = {
    popover: {
        position: 'absolute',
        zIndex: '2',
    },
    cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px'
    },
}

export default function ColorField({
    id,
    label,
    subtitle,
    value,
    identifier,
    handleBlur,
    handleChange,
    suffix,
    xs,
    touched,
    errorMessage,
}) {
    const{ setFieldValue } = useFormikContext();
    const [ field ] = useField({ name: id });

    const [ display, setDisplay ] = useState(false);

    const displayColor = useMemo(() => {
        if (!field.value) {
            return '#EEEEEE';
        }
        return field.value;
    }, [field.value]);

    const onColorPickerClick = () => {
        setDisplay(true);
    }

    const closeColorPicker = () => {
        setDisplay(false);
    }

    const onColorChange = (color) => {
        setFieldValue(field.name, color.hex);
    }

    return (
        <Grid item xs={xs}>
            <Stack spacing={1}>
                <InputLabel html={identifier}>{label}</InputLabel>
                {subtitle && (<InputLabel>{subtitle}</InputLabel>)}
                <OutlinedInput
                    id={identifier}
                    name={id}
                    value={'點此改變'}
                    sx={{
                        backgroundColor: displayColor,
                        "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "black",
                        },
                    }}
                    onClick={onColorPickerClick}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    endAdornment={suffix ? <InputAdornment position="end">{suffix}</InputAdornment> : null}
                    placeholder=""
                    fullWidth
                    error={Boolean(touched && errorMessage)}
                />
                {display ? (<div style={styles.popover}>
                    <div role='button' tabIndex={0} onClick={closeColorPicker} onKeyDown={() => {}} style={styles.cover} />
                    <SketchPicker 
                        color={field.value}
                        onChange={onColorChange}
                    />
                </div>) : null }
                {touched && errorMessage && (
                    <FormHelperText error id={`helper-text-${identifier}`}>
                        {errorMessage}
                    </FormHelperText>
                )}
            </Stack>
        </Grid>
    )
}