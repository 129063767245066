import { toast } from 'react-toastify';

const option = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    progress: undefined,
}
const error = (msg, opt = null) => {
    toast.error(msg, { ...option, ...opt });
}
const info = (msg, opt = null) => {
    toast.info(msg, { ...option, ...opt });
}
const success = (msg, opt = null) => {
    toast.success(msg, { ...option, ...opt });
}

const errorResp = (err) => {
    if (err & err.response || err.response.data || err.response.data.message) {
        error(err.response.data.message);
    } else {
        error(err.message);
    }
}
export default {
    info,
    error,
    success,
    errorResp,
}
