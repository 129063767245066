export const makeQueryString = (filtering, fieldList) => {
    let queryArr = [];
    if (filtering.page) {
        queryArr.push(`p=${filtering.page + 1}`);
    }

    delete filtering.page;

    for (const filter in filtering) {
        if (filtering[filter]) {
            let searchKey = filter;
            let searchValue = filtering[filter];
            let fieldObject = null;
            if (fieldList) {
                fieldObject = fieldList.find(s => s.id === filter);
            }
            if (fieldObject) {
                if (fieldObject.type && fieldObject.type.includes('select')) {
                    let final = JSON.stringify(searchValue);
                    final = encodeURIComponent(final);
                    searchValue = final;
                }
            }
            queryArr.push(`${searchKey}=${searchValue}`);
        }
    }

    const combined = queryArr.join('&');

    return `?${combined}`;
}

export const getDefaultByList = (fieldList, search) => {
    const searchParams = new URLSearchParams(search);

    const output = {};
    
    for (let i = 0; i < fieldList.length; i++) {
        const id = fieldList[i].id;
        const value = searchParams.get(id);
        if (fieldList[i].type && fieldList[i].type.includes('select')) {
            if (value) {
                const decoded = JSON.parse(value);
                if (decoded) {
                    output[id] = decoded;
                } else {
                    output[id] = null;
                }
            }
        } else {
            if (value) {
                output[id] = value;
            } else {
                output[id] = '';
            }
        }
    }

    return output;
}

export const getDefaultPage = (search) => {
    const searchParams = new URLSearchParams(search);

    const p = searchParams.get('p');

    return p ? p - 1: 0;
}