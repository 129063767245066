import { useState } from 'react';
import {
    Grid,
    Button,
    Stack,
    InputLabel,
} from '@mui/material';

import { FaSearch } from 'react-icons/fa';

import _ from 'lodash';

import DynamicField from 'components/field/DynamicField';
import useDebounce from 'hooks/useDebounce';

export default function SearchBar({
    fieldList,
    searchValue,
    setSearchValue,
}) {
    const [ value, setValue ] = useState(searchValue);

    const handleSearchValueChange = (id, fieldtype, e) => {
        const og = _.clone(value);
        if (fieldtype === 'select' || 
        fieldtype === 'asyncselect' || 
        fieldtype === 'selectmulti' ||
        fieldtype === 'asyncselectmulti' ||
        fieldtype === 'date') {
            og[id] = e;
        } else {
            og[id] = e.target.value;
        }
        
        setValue(og)
    }

    const setValueToParent = () => {
        setSearchValue(_.clone(value));
    }

    //useDebounce(setValueToParent, 500, [value]);

    return (
        <Grid container spacing={1}>
            {fieldList.map((field, index) => {
                if (field.type === 'search') {
                    return (
                        <Grid item xs={field.xs}>
                            <Stack spacing={1}>
                            <InputLabel>行動</InputLabel>
                                <Button variant="contained" onClick={setValueToParent} endIcon={<FaSearch />}>
                                    {field.label}
                                </Button>
                            </Stack>
                        </Grid>
                    )
                } else {
                    return (
                        <DynamicField
                            {...field}
                            key={index}
                            identifier={'search'}
                            value={value[field.id]}
                            isSearch={true}
                            handleChange={(e) => handleSearchValueChange(field.id, field.type, e)}
                        />
                    )
                }
            })}
        </Grid>
    )
}