import { lazy } from 'react';
import Loadable from 'components/Loadable';

// assets
import { MdPeople } from 'react-icons/md';
import { BsFillPersonFill } from 'react-icons/bs';
import { AiOutlineIdcard } from 'react-icons/ai';

// icons
const icons = {
    MdPeople,
    BsFillPersonFill,
    AiOutlineIdcard,

};

const UserList = Loadable(lazy(() => import('pages/user/UserList')));
const PersonalInformation = Loadable(lazy(() => import('pages/user/PersonalInformation')));
const NameCardList = Loadable(lazy(() => import('pages/user/NameCardList')));

import constant from '../utils/constant';
const UserRoles = constant.UserRoles;

const userList = {
    id: 'user',
    title: '會員',
    path: 'UserList',
    page: <UserList />,
    icon: icons.MdPeople,
    drawer: true,
    roles: [ UserRoles.SystemAdmin, UserRoles.HeadAdmin, UserRoles.AssoAdmin ],
}

const personalInformation = {
    id: 'personalInformation',
    title: '個人資料',
    path: 'PersonalInformation',
    page: <PersonalInformation />,
    icon: icons.BsFillPersonFill,
    drawer: true,
    roles: [ UserRoles.Coach, UserRoles.CoachAssitant,UserRoles.AssoAdmin ],
}

const nameCardList = {
    id: 'nameCardList',
    title: '助教證',
    path: 'NameCardList',
    page: <NameCardList />,
    icon: icons.AiOutlineIdcard,
    drawer: true,
    roles: [ UserRoles.SystemAdmin,UserRoles.HeadAdmin ],
}

const manage = {
    path: 'manage',
    title: '管理',
    children: [ userList, personalInformation, nameCardList ],
}

export default manage;