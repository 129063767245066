import { 
    Grid,
    Stack,
    InputLabel,
    FormHelperText,
} from '@mui/material';
import { useField, useFormikContext } from 'formik';
import Select from 'react-select';

export default function SelectField({
    id,
    identifier,
    xs,
    label,
    subtitle,
    handleBlur,
    isMulti,
    options,
    touched,
    errorMessage,
}) {
    const{ setFieldValue } = useFormikContext();
    const [ field ] = useField({ name: id });

    const onChangeHandler = (val) => {
        setFieldValue(field.name, val);
    }

    return (
        <Grid item xs={xs}>
            <Stack spacing={1}>
                <InputLabel html={identifier}>{label}</InputLabel>
                {subtitle && (<InputLabel>{subtitle}</InputLabel>)}
                <Select 
                    options={options}
                    value={field.value}
                    isMulti={isMulti}
                    onBlur={handleBlur}
                    onChange={onChangeHandler}
                    styles={{
                        control: (base, state) => ({
                            ...base,
                            borderColor: Boolean(touched && errorMessage) ? 'red': 'lightgray',
                        })
                    }}
                />
                {touched && errorMessage && (
                    <FormHelperText error id={`helper-text-${identifier}`}>
                        {errorMessage}
                    </FormHelperText>
                )}
            </Stack>
        </Grid>
    )
}