import { useEffect, useState, useMemo } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'universal-cookie';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Toolbar, useMediaQuery } from '@mui/material';

// project import
import Drawer from './Drawer';
import Header from './Header';
import { getDrawItems, isAuthorized } from 'menu-items';
import Breadcrumbs from 'components/@extended/Breadcrumbs';

// types
import { openDrawer } from 'store/reducers/menu';

import constant from 'utils/constant';

const cookies = new Cookies();

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const theme = useTheme();
    const matchDownLG = useMediaQuery(theme.breakpoints.down('xl'));
    const dispatch = useDispatch();

    const currentUserInfo = useMemo(() => {
        const userInfo = cookies.get(constant.USERINFO, { path: '/' });
        return userInfo;
    }, []);

    useEffect(() => {
        if (!currentUserInfo) {
            navigate(constant.loginPage);
        }
    }, [currentUserInfo, navigate]);

    useEffect(() => {
        if (currentUserInfo) {
            const authorized = isAuthorized(location.pathname, currentUserInfo.userrole);    
            if (!authorized) {
                navigate(constant.homePage);
            }
        }
    }, [location, currentUserInfo, navigate]);

    const { drawerOpen } = useSelector((state) => state.menu);

    // drawer toggler
    const [open, setOpen] = useState(drawerOpen);
    const handleDrawerToggle = () => {
        setOpen(!open);
        dispatch(openDrawer({ drawerOpen: !open }));
    };

    // set media wise responsive drawer
    useEffect(() => {
        setOpen(!matchDownLG);
        dispatch(openDrawer({ drawerOpen: !matchDownLG }));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownLG]);

    useEffect(() => {
        if (open !== drawerOpen) setOpen(drawerOpen);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [drawerOpen]);

    const navigation = useMemo(() => {
        if (!currentUserInfo) return { items: [] };
        const menuItems = getDrawItems(currentUserInfo.userrole);
        return menuItems;
    }, [currentUserInfo]);

    return (
        <Box sx={{ display: 'flex', width: '100%' }}>
            <Header open={open} handleDrawerToggle={handleDrawerToggle} />
            <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
            <Box component="main" sx={{ width: '100%', flexGrow: 1, p: { xs: 2, sm: 3 } }}>
                <Toolbar />
                <Breadcrumbs navigation={navigation} title titleBottom card={false} divider={false} />
                <Outlet />
            </Box>
        </Box>
    );
};

export default MainLayout;
