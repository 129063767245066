import { useMemo, useEffect } from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';

import * as Yup from 'yup';
import BaseForm from 'components/material/BaseForm';
import Loader from 'components/Loader';

import BookingService from 'services/booking';

import useAuthentication from 'hooks/useAuthentication';

import { parseCoachList, parseBookingVenusList, parseArrayToSelect, parseAssociationList } from 'utils/wrapper/SelectParser';
import YupValidate from 'utils/wrapper/YupValidate';
import toastHelper from 'utils/toastHelper';
import { fieldListToDefaultValue, getRequestToForm, formToPostRequest } from 'utils/helper/form';
import MemberShipYearService from 'services/membershipYear';

const defaultFieldList = [
    {
        id: 'fromYear',
        label: '開始年份',
        xs: 6,
    },
    {
        id: 'toYear',
        label: '完結年份',
        xs: 6,
    }
]

export default function MemberShipYearForm({
    open,
    handleclose,
    editingYear,
    onYearUpdated,
}) {
    const yearId = useMemo(() => {
        if (editingYear) {
            return editingYear.id;
        }
        return -1;
    }, [editingYear]);

    const { data: yearData, isLoading: isLoadingYear } = useQuery(["id", yearId, open], () => {
        if (open && yearId !== -1) {
            return MemberShipYearService.get(yearId);
        }
        return null;
    });

    const title = useMemo(() => {
        if (editingYear) {
            return `更改續會年份`;
        }
        return '新增續會年份';
    }, [editingYear]);


    const confirmText = useMemo(() => {
        if (editingYear) {
            return '更改';
        }

        return '確定';
    }, [editingYear]);

    const formDefaultValue = useMemo(() => {
        if (editingYear) {
            if (!isLoadingYear) {
                try {
                    const serverData = yearData?.data?.content;
                    if (serverData) {
                        const data = getRequestToForm(serverData, defaultFieldList);
                        return data;
                    }
                } catch (e) {
                    console.log(e);
                }
            }
            const data = fieldListToDefaultValue(defaultFieldList);
            return data;
        }

        const data = fieldListToDefaultValue(defaultFieldList);
        return data;
    }, [editingYear, yearData, isLoadingYear, open]);

    const submitMutation = useMutation(data => {
        if (editingYear) {
            data.id = editingYear.id;
        }
        return MemberShipYearService.manage_year(data);
    }, {
        onSuccess: (response, variables, context) => {
            toastHelper.success("續會年份更改成功");
            onYearUpdated();
        },
        onError: (err) => {
            toastHelper.errorResp(err)
        }
    })

    if (editingYear && isLoadingYear) {
        return <Loader />;
    }
    return (
        <BaseForm
            open={open}
            handleclose={handleclose}
            identifier={'year'}
            fieldList={defaultFieldList}
            title={title}
            confirmText={confirmText}
            initialValues={formDefaultValue}
            validationSchema={Yup.object().shape({
                fromYear: YupValidate.positiveNumber(50000),
                toYear: YupValidate.positiveNumber(50000),
            })}
            onSubmitHandler={async (data) => {
                const postData = formToPostRequest(data, defaultFieldList);

                delete postData.bookingVenueSlots;
                submitMutation.mutate(postData);
            }}
        />
    )
}