import {
    Grid,
    Checkbox,
    InputLabel,
    Stack,
    FormHelperText,
} from '@mui/material';

export default function CheckboxField({
    id,
    xs,
    value,
    handleChange,
    handleBlur, 
    errorMessage, 
    label, 
    subtitle,
}) {
    return (
        <Grid item xs={xs}>
            <Stack direction="row" alignItems="center" spacing={1}>
                <Checkbox
                    id={id}
                    name={id}
                    checked={value}
                    onChange={(e) => {
                        handleChange(e)
                    }}
                    sx={{
                        color: !value && errorMessage ? 'red' : '',
                    }}
                />
                <InputLabel htmlFor={id}>
                    <Grid container>
                        <Grid item xs={12}>
                            {label}
                        </Grid>
                        <Grid item xs={12}>
                            {subtitle}
                        </Grid>
                    </Grid>
                </InputLabel>
            </Stack>
            <FormHelperText error={!value && errorMessage}>{!value && errorMessage}</FormHelperText>
        </Grid>
    )
}