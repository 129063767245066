import MainLayout from 'layout/MainLayout';

import { getRoutes } from 'menu-items';
const routeList = getRoutes();

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: routeList,
};

export default MainRoutes;
