import {
    Grid,
    Stack,
    InputLabel,
    FormHelperText,
    TextField,
    IconButton
} from '@mui/material';
import { useField, useFormikContext } from 'formik';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { MdClear} from 'react-icons/md';
export default function DateField({
    id,
    identifier,
    xs,
    label,
    subtitle,
    touched,
    errorMessage,
    handleBlur,
}) {
    const { setFieldValue } = useFormikContext();
    const [field] = useField({ name: id });
    const handleClr = (e) =>{
        e.stopPropagation();
        setFieldValue(field.name,null);
    }
    return (
        <Grid item xs={xs}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack spacing={1}>
                    <InputLabel html={identifier}>{label}</InputLabel>
                    {subtitle && (<InputLabel>{subtitle}</InputLabel>)}
                    <MobileDatePicker
                        id={identifier}
                        value={field.value}
                        onBlur={handleBlur}
                        onChange={val => {
                            setFieldValue(field.name, val)
                        }}
                        inputFormat="DD/MM/YYYY"
                        fullWidth
                        InputProps={{
                            endAdornment: (
                              <IconButton onClick={(e) => handleClr(e)}>
                                <MdClear />
                              </IconButton>
                            )
                          }}
                        renderInput={(params) => <TextField
                            {...params}
                            error={Boolean(touched && errorMessage)}
                        />}

                    />
                    {touched && errorMessage && (
                        <FormHelperText error id={`helper-text-${identifier}`}>
                            {errorMessage}
                        </FormHelperText>
                    )}
                </Stack>
            </LocalizationProvider>
        </Grid>
    )
}