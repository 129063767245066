import { useEffect, useMemo } from 'react';
import {
    Grid,
    Stack,
    InputLabel,
    Button,
} from '@mui/material';
import { useField, useFormikContext } from 'formik';
import _ from 'lodash';
import dayjs from 'dayjs';

import DynamicField from './DynamicField';
import ListActionButton from './ListActionButton';

import { fieldListToDefaultValue } from 'utils/helper/form';

export default function ListField({
    id,
    identifier,
    label,
    subtitle,
    xs,
    data,
    noAdd,
    touched,
    handleBlur,
    handleChange,
    errorMessage,
    hasCalculation,
}) {
    const { setFieldValue } = useFormikContext();
    const [field] = useField({ name: id });

    const listDefaultItem = useMemo(() => {
        const item = fieldListToDefaultValue(data, []);
        return item;
    }, [data]);

    const onListAddHandler = () => {
        const previous = _.cloneDeep(field.value);
        previous.push(listDefaultItem);
        setFieldValue(field.name, previous);
    }

    const onListButtonClickHandler = (action, index) => {
        switch (action) {
            case 'remove':
                onListRemoveHandler(index);
                break;
            case 'up':
                onListUpHandler(index);
                break;
            case 'down':
                onListDownHandler(index);
                break;
            case 'copy':
                onListCopyHandler(index);
            default:
                break;
        }
    }

    const onListRemoveHandler = (index) => {
        const previous = _.cloneDeep(field.value);
        previous.splice(index, 1);
        setFieldValue(field.name, previous);
    }

    const onListUpHandler = (index) => {
        const previous = _.cloneDeep(field.value);
        const previousIndex = index - 1;
        const tmp = previous[previousIndex];
        previous[previousIndex] = previous[index];
        previous[index] = tmp;
        setFieldValue(field.name, previous);
    }

    const onListDownHandler = (index) => {
        const previous = _.cloneDeep(field.value);
        const nextIndex = index + 1;
        const tmp = previous[nextIndex];
        previous[nextIndex] = previous[index];
        previous[index] = tmp;
        setFieldValue(field.name, previous);
    }

    const onListCopyHandler = (index) => {
        const previous = _.cloneDeep(field.value);
        const tmp = _.cloneDeep(previous[index]);
        for (let i = 0; i < data.length; i++) {
            const row = data[i];
            if (row.type === 'action') continue;
            if (row.copy === 'default') {
                tmp[row.id] = listDefaultItem[row.id];
            }
        }
        previous.push(tmp);
        setFieldValue(field.name, previous);
    }

    const onBlurHandler = (e) => {
        handleBlur(e);
    }

    const onChangeHandler = (e) => {
        handleChange(e);
    }

    const calculatedTime = useMemo(() => {
        if (!hasCalculation) return false;
        const formtype = hasCalculation.type;
        const typeConfig = data.find(s => s.id === formtype);
        if (!typeConfig) return false;
        const previous = _.cloneDeep(field.value);

        const endTime = hasCalculation.end
        const startTime = hasCalculation.start
       
        const typeList = typeConfig.options.map(s => s.label);


        const timeList = [];
        for (let i = 0; i < typeList.length; i++) {
            const currentType = typeList[i];
            const currentTypeList = previous.filter(s => s[formtype]?.label === currentType);
            let totalMs = 0
            for (let j = 0; j < currentTypeList.length; j++) {
                const start = currentTypeList[j][startTime];
                const end = currentTypeList[j][endTime];
                const diff = dayjs(end).diff(dayjs(start));
                totalMs += diff;
            }
            // parse total ms to total hour
            const totalHour = totalMs / 1000 / 60 / 60;
            timeList.push({
                label: currentType,
                hour: Number(totalHour.toPrecision(2)),
            });
        }

        return (
            <Grid container spacing={1}>
                {timeList.map((time, index) => (
                    <Grid item xs={12} key={`${identifier}-time-${index}`} style={{fontSize:20}}>
                        {time.label}: {time.hour} 小時
                    </Grid>
                ))}
            </Grid>
        )
    }, [field.value, hasCalculation])

    return (
        <Grid item xs={xs}>
            <Stack spacing={1}>
                <Grid container spacing={1}>
                    <Grid item xs={8}>
                        <InputLabel html={identifier}>{label}</InputLabel>
                        {subtitle && (<InputLabel>{subtitle}</InputLabel>)}
                    </Grid>
                    <Grid item xs={4}>
                        {!noAdd && (
                            <Button
                                onClick={onListAddHandler}
                                variant="outlined"
                            >增加</Button>
                        )}

                    </Grid>
                </Grid>
                <Grid container justifyContent="center" spacing={1}>
                    {data.map((header) => (
                        <Grid
                            item
                            xs={header.xs}
                            key={`${identifier}-header-${header.id}`}
                        >
                            {header.header}
                        </Grid>
                    )
                    )}
                </Grid>
                {field.value.map((row, index) => {
                    return (
                        <Grid container spacing={1} key={`${identifier}-row-${index}-content`} style={{ alignItems: "center" }}>
                            {data.map((content) => {
                                if (content.type === 'action') {
                                    return (
                                        <ListActionButton
                                            key={`${identifier}-row-${index}-content-action`}
                                            identifier={identifier}
                                            xs={content.xs}
                                            buttonList={content.buttonList}
                                            withLabel={content.withLabel}
                                            handler={onListButtonClickHandler}
                                            index={index}
                                            totalLength={field.value.length}
                                        />
                                    )
                                }
                                return (
                                    <DynamicField
                                        {...content}
                                        id={`${id}[${index}].${content.id}`}
                                        identifier={`${identifier}-row-${index}-${content.id}`}
                                        key={`${identifier}-row-${index}-${content.id}`}
                                        value={row[content.id]}
                                        touched={touched && touched[index] && touched[index][content.id]}
                                        errorMessage={errorMessage && errorMessage[index] && errorMessage[index][content.id]}
                                        handleBlur={onBlurHandler}
                                        handleChange={onChangeHandler}
                                        index={index}
                                    />
                                )
                            })}
                        </Grid>
                    )
                })}
            {hasCalculation && (
                <div>{calculatedTime}</div>
            )}
            </Stack>
        </Grid>
    )
}