import constant from "utils/constant";

export const isSuper = (userRole) => {
    if (userRole === constant.UserRoles.SystemAdmin) return true;
    if (userRole === constant.UserRoles.HeadAdmin) return true;
    return false;
}

export const isAssociationRelated = (userRole) => {
    if (userRole === constant.UserRoles.AssoAdmin) return true;
    if (userRole === constant.UserRoles.AssoMember) return true;
    if (userRole === constant.UserRoles.Coach) return true;
    if (userRole === constant.UserRoles.CoachAssitant) return true;
    return false;
}

export const isCoach = (userRole) => {
    if (userRole === constant.UserRoles.Coach) return true;
    return false;
}
export const isAssoAdmin = (userRole) => {
    if (userRole === constant.UserRoles.AssoAdmin) return true;
    return false;
}