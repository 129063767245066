import _ from 'lodash';
import dayjs from 'dayjs';
import { defaultOption, parseUserToSelect } from 'utils/wrapper/SelectParser';

export const fieldListToDefaultValue = (fieldList) => {
    const data = {};
    for (let i = 0; i < fieldList.length; i++) {
        const field = fieldList[i];
        if (field.type === 'button') continue;
        if (field.type === 'action') continue;
        if (field.type === 'label') continue;
        if (field.type === 'component') continue;

        if (field.type === 'checkbox') {
            data[field.id] = false;
        }
        else if (field.type === 'select') {
            let list = field.options;
            if (list) {
                data[field.id] = list[0];
            } else {
                data[field.id] = null;
            }
        }
        else if (field.type === 'asyncselect') {
            data[field.id] = defaultOption;
        }
        else if (field.type === 'selectmulti' || field.type === 'asyncselectmulti') {
            data[field.id] = [];
        }
        else if (field.type === 'list') {
            const listDefaultValue = fieldListToDefaultValue(field.data);
            const list = [];
            if (field.defaultNumber) {
                for (let i = 0; i < field.defaultNumber; i++) {
                    const listDefault = _.cloneDeep(listDefaultValue);
                    list.push(listDefault);
                }
            }
            data[field.id] = list;
        }
        else if (field.type === 'date' || field.type === 'datetime') {
            data[field.id] = dayjs();
        } else if (field.type === 'image') {
            data[field.id] = null
        } else {
            data[field.id] = '';
        }
    }

    return data;
}

export const formToPostRequest = (data, fieldList) => {
    const output = _.cloneDeep(data);

    fieldList.forEach(field => {
        if (field.type === 'select' || field.type === 'asyncselect') {
            if (data[field.id]) {
                output[field.id] = data[field.id].value;
            }
        }
        if (field.type === 'selectmulti' || field.type === 'asyncselectmulti') {
            let list = [];
            for (let i = 0; i < data[field.id].length; i++) {
                list.push(data[field.id][i].value);
            }
            output[field.id] = list;
        }
        if (field.type === 'list') {
            const listValue = [];
            const formListValue = data[field.id];
            for (let i = 0; i < formListValue.length; i++) {
                listValue.push(formToPostRequest(formListValue[i], field.data));
            }
            output[field.id] = listValue;
        }
        if (field.type === 'date') {
            if (data[field.id]) {
                output[field.id] = dayjs(data[field.id]).format();
            }
        }

        if (field.type === 'checkbox') {
            if (typeof data[field.id] === 'boolean') {
                output[field.id] = data[field.id];
            } else if (Array.isArray(data[field.id])) {
                output[field.id] = false;
                if (data[field.id].length >= 1) {
                    if (data[field.id][0] === 'on') {
                        output[field.id] = true;
                    }
                }
            } else {
                output[field.id] = false;
            }
        }
    });

    return output;
}

export const getRequestToForm = (data, fieldList) => {
    const output = _.cloneDeep(data);

    fieldList.forEach(field => {
        if (field.type === 'select') {
            let list = field.options;
            //for some select option value is 0, it will make the below condition to false event it has value
            if (data[field.id] || data[field.id] === 0) {
                const option = list.find(s => s.value === data[field.id]);

                output[field.id] = option;
            } else {
                output[field.id] = list[0];
            }
        } else if (field.type === 'asyncselect') {
            if (field.defaultLabel) {
                const labelTargetArr = field.defaultLabel.split('.');
                let label = null;
                try {
                    label = data;
                    for (let i = 0; i < labelTargetArr.length; i++) {
                        label = label[labelTargetArr[i]];
                    }
                } catch (e) {
                    console.log(e);
                }

                if (label) {
                    if (!field.isUser) {
                        output[field.id] = {
                            label: label,
                            value: data[field.id],
                        }
                    } else {
                        const userSelect = parseUserToSelect(label);
                        output[field.id] = userSelect;
                    }
                }
            }
        } else if (field.type === 'asyncselectmulti') {
            let list = [];
            const labelTargetArr = field.listObject.split('.');
            let listObj = null;
            try {
                listObj = data;
                for (let i = 0; i < labelTargetArr.length; i++) {
                    listObj = listObj[labelTargetArr[i]];
                }
            } catch (e) {
                console.log(e);
            }

            if (listObj) {
                for (let i = 0; i < listObj.length; i++) {
                    const innerLabelTargetArr = field.defaultLabel.split('.');
                    let labelObj = null
                    try {
                        labelObj = listObj[i];
                        for (let j = 0; j < innerLabelTargetArr.length; j++) {
                            labelObj = labelObj[innerLabelTargetArr[j]];
                        }
                    } catch (e) {
                        console.log(e);
                    }

                    if (!field.isUser) {
                        list.push({
                            label: labelObj,
                            value: data[field.id]
                        })
                    } else {
                        const userSelect = parseUserToSelect(labelObj);
                        list.push(userSelect);
                    }
                }
                output[field.id] = list;
            }
        } else if (field.type === 'list') {
            const inner = field.data;
            const requestList = data[field.id];

            const formList = [];
            if (requestList) {
                for (let i = 0; i < requestList.length; i++) {
                    formList.push(getRequestToForm(requestList[i], inner));
                }
            } else if (field.defaultNumber) {
                const listDefaultValue = fieldListToDefaultValue(field.data);
                for (let i = 0; i < field.defaultNumber; i++) {
                    const listDefault = _.cloneDeep(listDefaultValue);
                    formList.push(listDefault);
                }
            }
            output[field.id] = formList;
        } else if (field.type === 'image') {
            if (data[field.id]) {
                output[field.id] = {
                    type: 'server',
                    message: '已上傳過相片',
                    file: data[field.id],
                }
            } else {
                output[field.id] = null
            }
        } else if (field.type === 'date' || field.type === 'datetime') {
            if (data[field.id]) {
                output[field.id] = data[field.id]
            } else {
                output[field.id] = null
            }
        } else {
            if (data[field.id]) {
                output[field.id] = data[field.id]
            } else {
                output[field.id] = ''
            }
        }
    });

    return output;
}

export const getChangedValue = (values, initialValues, oldvalue = false) => {
    return Object
        .entries(values)
        .reduce((acc, [key, value]) => {
            const hasChanged = initialValues[key] !== value
            if (hasChanged) {
                if (oldvalue) {
                    acc[key] = initialValues[key]
                } else {
                    acc[key] = value
                }

            }
            return acc
        }, {})
}