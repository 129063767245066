import { lazy } from 'react';
import Loadable from 'components/Loadable';

// assets
import { FaClipboardList } from 'react-icons/fa';

const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));

// icons
const icons = {
    FaClipboardList
};

const HomePage = {
    id: 'home',
    title: '主頁',
    path: 'default',
    page: <DashboardDefault />,
    icon: icons.FaClipboardList,
    drawer: true,
    roles: '*',
}

const dashboard = {
    path: 'dashboard',
    title: '主頁',
    children: [],
}

export default dashboard;
