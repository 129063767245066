import axios from './interceptor';

const list = (payload) => {
    return axios.get(`/bookings`, {
        params: payload
    });
}

const get = (id) => {
    return axios.get(`/booking`, { params: { id } });
}

const item = (payload) => {
    return axios.get(`/bookingitem`, {
        params: payload
    });
}

const item_byasso = (payload) => {
    return axios.get(`/bookingitems`, {
        params: payload
    });
}

const calendar = (payload) => {
    return axios.get(`/bookingcalendar`, {
        params: payload
    });
}

const batch_approve = (data) => {
    return axios.post(`/batchapprovebookingitemrequest`, data);
}

const batch_manage = (data) => {
    return axios.post(`/batchchangebookingitemstatusrequest`, data)
}

const manage = (data) => {
    return axios.post(`/managebookingrequest`, data);
}

const manage_item = (data) => {
    return axios.post(`/managebookingitemrequest`, data);
}

const asso_manage = (data) => {
    return axios.post(`/assomanagebookingrequest`, data);
}

const venue = (id) => {
    return axios.get(`/bookingvenue`, { params: { id } });
}

const venues = (payload) => {
    return axios.get(`/bookingvenues`, {
        params: payload
    });
}

const sortVenues = (data) => {
    return axios.post(`/sortbookingvenues`, data)
}

const manage_venus = (data) => {
    return axios.post(`/managebookingvenue`, data);
}

const booking = {
    list,
    get,
    batch_approve,
    batch_manage,
    manage_item,
    manage,
    asso_manage,
    item,
    item_byasso,
    calendar,
    venue,
    venues,
    sortVenues,
    manage_venus,
}

export default booking;